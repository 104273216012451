import ModelElementType from '@/models/ModelElementType';
import Point from '@/models/Point';
import { JsonProperty, Serializable } from 'typescript-json-serializer';
import PlacementRule from '@/models/PlacementRule';
import InsidePlacementRule from '@/models/InsidePlacementRule';
import AttributeType from '@/models/AttributeType';

@Serializable()
export default class NodeType extends ModelElementType {
  @JsonProperty({
    name: 'connectPoints',
    type: Point,
  })
  private _connectPoints: Point[] = [];

  @JsonProperty({
    name: 'insidePlacementRules',
    type: InsidePlacementRule,
  })
  private _insidePlacementRules: PlacementRule[] = [];

  constructor(
    typeName: string,
    attributeTypes: AttributeType[],
    display: string,
    insidePlacementRules: InsidePlacementRule[] = []
  ) {
    super(typeName, attributeTypes, display);
    this._insidePlacementRules = insidePlacementRules;
  }

  get connectPoints(): Point[] {
    return this._connectPoints;
  }

  set connectPoints(value: Point[]) {
    this._connectPoints = value;
  }

  get insidePlacementRules(): PlacementRule[] {
    return this._insidePlacementRules;
  }

  set insidePlacementRules(value: PlacementRule[]) {
    this._insidePlacementRules = value;
  }
}
