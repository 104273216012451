import MainService from '@/services/MainService';
import { deserialize, serialize } from 'typescript-json-serializer';

import Tag from '@/models/users/Tag';
import { AxiosResponse } from 'axios';

export default class TagService {
  private static mainURL = '/tag';

  public static getAll(): Promise<Tag[]> {
    return MainService.get(this.mainURL).then((response) => {
      return response.data.map((item) => deserialize(item, Tag));
    });
  }

  public static save(tag: Tag): Promise<Tag> {
    return MainService.post(this.mainURL, JSON.stringify(serialize(tag))).then((response) => {
      return deserialize(response.data, Tag);
    });
  }

  public static delete(tagId: number): Promise<AxiosResponse> {
    return MainService.delete(this.mainURL + '/' + tagId + '/');
  }
}
