import { PathFindingToupleInfo } from '@/pathfinding/PathFinding';

export default class PathFindingLinear {
  /**
   * Returns edge endpoint
   * @param toupleInfo
   */
  public static getSegmentPoints(toupleInfo: PathFindingToupleInfo) {
    const { startPoint, endPoint } = toupleInfo;

    const pdx = endPoint.x - startPoint.x;
    const pdy = endPoint.y - startPoint.y;

    return [[pdx, pdy]];
  }
}
