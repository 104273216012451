import { PossibleAction } from '@/auth/PossibleAction';

const authMatrixAdmin: Record<string, boolean> = {
  // NAVIGATIONS
  [PossibleAction.CAN_NAVIGATE_ADMINISTRATION]: true,
  [PossibleAction.CAN_NAVIGATE_TAGS]: true,
  [PossibleAction.CAN_NAVIGATE_PROJECTS]: true,
  [PossibleAction.CAN_NAVIGATE_PROJECT_DETAILS]: true,
  [PossibleAction.CAN_NAVIGATE_MODEL_EDITOR]: true,
  [PossibleAction.CAN_NAVIGATE_SCOPES]: true,
  [PossibleAction.CAN_NAVIGATE_ASSIGNMENT_CREATION]: true,
  [PossibleAction.CAN_NAVIGATE_ASSIGNMENT_DETAILS]: true,
  [PossibleAction.CAN_NAVIGATE_REVIEW_PROCESS]: true,
  [PossibleAction.CAN_NAVIGATE_CONFIGS]: true,
  [PossibleAction.CAN_NAVIGATE_CONFIG_DETAILS]: true,
  [PossibleAction.CAN_NAVIGATE_REVIEWS]: true,
  [PossibleAction.CAN_NAVIGATE_AUDIT]: true,
  [PossibleAction.CAN_NAVIGATE_PIPELINES]: true,

  // AUDIT LOGGING
  [PossibleAction.CAN_GET_AUDIT]: true,

  // MODEL CONFIG
  [PossibleAction.CAN_GET_CONFIG_IDS]: true,
  [PossibleAction.CAN_GET_CONFIG]: true,
  [PossibleAction.CAN_SAVE_CONFIG]: true,
  [PossibleAction.CAN_UPDATE_CONFIG]: true,
  [PossibleAction.CAN_DELETE_CONFIG]: true,

  // MODEL
  [PossibleAction.CAN_GET_MODEL]: true,
  [PossibleAction.CAN_SAVE_MODEL]: true,
  [PossibleAction.CAN_UPDATE_MODEL]: true,
  [PossibleAction.CAN_DELETE_MODEL]: true,

  // MODEL ANALYSIS
  [PossibleAction.CAN_ANALYSE_MODEL]: true,

  // MODEL IMPORT/EXPORT
  [PossibleAction.CAN_IMPORT_MODEL]: true,
  [PossibleAction.CAN_EXPORT_MODEL]: true,

  // BACKGROUND IMAGE
  [PossibleAction.CAN_GET_IMAGE]: true,
  [PossibleAction.CAN_SAVE_IMAGE]: true,
  [PossibleAction.CAN_DELETE_IMAGE]: true,

  // MODEL SCOPE
  [PossibleAction.CAN_GET_SCOPE]: true,
  [PossibleAction.CAN_SAVE_SCOPE]: true,
  [PossibleAction.CAN_DELETE_SCOPE]: true,

  // PROJECT
  [PossibleAction.CAN_GET_PROJECT]: true,
  [PossibleAction.CAN_SAVE_PROJECT]: true,
  [PossibleAction.CAN_UPDATE_PROJECT]: true,
  [PossibleAction.CAN_DELETE_PROJECT]: true,

  // REVIEW ASSIGNMENT
  [PossibleAction.CAN_GET_ASSIGNMENT_FOR_EDITOR]: true,
  [PossibleAction.CAN_GET_ASSIGNMENT]: true,
  [PossibleAction.CAN_SAVE_ASSIGNMENT]: true,
  [PossibleAction.CAN_UPDATE_ASSIGNMENT]: true,
  [PossibleAction.CAN_DELETE_ASSIGNMENT]: true,
  [PossibleAction.CAN_EXPORT_ASSIGNMENT]: true,

  // REVIEW
  [PossibleAction.CAN_GET_REVIEW]: true,
  [PossibleAction.CAN_SAVE_REVIEW]: true,
  [PossibleAction.CAN_UPDATE_REVIEW]: true,
  [PossibleAction.CAN_DELETE_REVIEW]: true,

  // TAGS
  [PossibleAction.CAN_GET_TAG]: true,
  [PossibleAction.CAN_SAVE_TAG]: true,
  [PossibleAction.CAN_DELETE_TAG]: true,

  // USER INFO
  [PossibleAction.CAN_GET_USER_INFO]: true,

  // USER TAGS
  [PossibleAction.CAN_GET_USER_TAGS]: true,
  [PossibleAction.CAN_SAVE_USER_TAGS]: true,
  [PossibleAction.CAN_UPDATE_USER_TAGS]: true,
  [PossibleAction.CAN_DELETE_USER_TAGS]: true,

  // PIPELINES
  [PossibleAction.CAN_RUN_PIPELINE]: true,
};

const authMatrixDesigner: Record<string, boolean> = {
  // NAVIGATIONS
  [PossibleAction.CAN_NAVIGATE_ADMINISTRATION]: false,
  [PossibleAction.CAN_NAVIGATE_TAGS]: false,
  [PossibleAction.CAN_NAVIGATE_PROJECTS]: true,
  [PossibleAction.CAN_NAVIGATE_PROJECT_DETAILS]: true,
  [PossibleAction.CAN_NAVIGATE_MODEL_EDITOR]: true,
  [PossibleAction.CAN_NAVIGATE_SCOPES]: true,
  [PossibleAction.CAN_NAVIGATE_ASSIGNMENT_CREATION]: false,
  [PossibleAction.CAN_NAVIGATE_ASSIGNMENT_DETAILS]: false,
  [PossibleAction.CAN_NAVIGATE_REVIEW_PROCESS]: false,
  [PossibleAction.CAN_NAVIGATE_CONFIGS]: true,
  [PossibleAction.CAN_NAVIGATE_CONFIG_DETAILS]: true,
  [PossibleAction.CAN_NAVIGATE_REVIEWS]: false,
  [PossibleAction.CAN_NAVIGATE_AUDIT]: false,
  [PossibleAction.CAN_NAVIGATE_PIPELINES]: true,

  // AUDIT LOGGING
  [PossibleAction.CAN_GET_AUDIT]: false,

  // MODEL CONFIG
  [PossibleAction.CAN_GET_CONFIG_IDS]: true,
  [PossibleAction.CAN_GET_CONFIG]: true,
  [PossibleAction.CAN_SAVE_CONFIG]: true,
  [PossibleAction.CAN_UPDATE_CONFIG]: true,
  [PossibleAction.CAN_DELETE_CONFIG]: true,

  // MODEL
  [PossibleAction.CAN_GET_MODEL]: true,
  [PossibleAction.CAN_SAVE_MODEL]: true,
  [PossibleAction.CAN_UPDATE_MODEL]: true,
  [PossibleAction.CAN_DELETE_MODEL]: true,

  // MODEL ANALYSIS
  [PossibleAction.CAN_ANALYSE_MODEL]: true,

  // MODEL IMPORT/EXPORT
  [PossibleAction.CAN_IMPORT_MODEL]: true,
  [PossibleAction.CAN_EXPORT_MODEL]: true,

  // BACKGROUND IMAGE
  [PossibleAction.CAN_GET_IMAGE]: true,
  [PossibleAction.CAN_SAVE_IMAGE]: true,
  [PossibleAction.CAN_DELETE_IMAGE]: true,

  // MODEL SCOPE
  [PossibleAction.CAN_GET_SCOPE]: true,
  [PossibleAction.CAN_SAVE_SCOPE]: true,
  [PossibleAction.CAN_DELETE_SCOPE]: true,

  // PROJECT
  [PossibleAction.CAN_GET_PROJECT]: true,
  [PossibleAction.CAN_SAVE_PROJECT]: true,
  [PossibleAction.CAN_UPDATE_PROJECT]: true,
  [PossibleAction.CAN_DELETE_PROJECT]: true,

  // REVIEW ASSIGNMENT
  [PossibleAction.CAN_GET_ASSIGNMENT_FOR_EDITOR]: true,
  [PossibleAction.CAN_GET_ASSIGNMENT]: false,
  [PossibleAction.CAN_SAVE_ASSIGNMENT]: false,
  [PossibleAction.CAN_UPDATE_ASSIGNMENT]: false,
  [PossibleAction.CAN_DELETE_ASSIGNMENT]: false,
  [PossibleAction.CAN_EXPORT_ASSIGNMENT]: false,

  // REVIEW
  [PossibleAction.CAN_GET_REVIEW]: false,
  [PossibleAction.CAN_SAVE_REVIEW]: false,
  [PossibleAction.CAN_UPDATE_REVIEW]: false,
  [PossibleAction.CAN_DELETE_REVIEW]: false,

  // TAGS
  [PossibleAction.CAN_GET_TAG]: false,
  [PossibleAction.CAN_SAVE_TAG]: false,
  [PossibleAction.CAN_DELETE_TAG]: false,

  // USER INFO
  [PossibleAction.CAN_GET_USER_INFO]: true,

  // USER TAGS
  [PossibleAction.CAN_GET_USER_TAGS]: false,
  [PossibleAction.CAN_SAVE_USER_TAGS]: false,
  [PossibleAction.CAN_UPDATE_USER_TAGS]: false,
  [PossibleAction.CAN_DELETE_USER_TAGS]: false,

  // PIPELINES
  [PossibleAction.CAN_RUN_PIPELINE]: false,
};

const authMatrixConfigurator: Record<string, boolean> = {
  // NAVIGATIONS
  [PossibleAction.CAN_NAVIGATE_ADMINISTRATION]: false,
  [PossibleAction.CAN_NAVIGATE_TAGS]: true,
  [PossibleAction.CAN_NAVIGATE_PROJECTS]: true,
  [PossibleAction.CAN_NAVIGATE_PROJECT_DETAILS]: true,
  [PossibleAction.CAN_NAVIGATE_MODEL_EDITOR]: false,
  [PossibleAction.CAN_NAVIGATE_SCOPES]: true,
  [PossibleAction.CAN_NAVIGATE_ASSIGNMENT_CREATION]: true,
  [PossibleAction.CAN_NAVIGATE_ASSIGNMENT_DETAILS]: true,
  [PossibleAction.CAN_NAVIGATE_REVIEW_PROCESS]: true,
  [PossibleAction.CAN_NAVIGATE_CONFIGS]: false,
  [PossibleAction.CAN_NAVIGATE_CONFIG_DETAILS]: false,
  [PossibleAction.CAN_NAVIGATE_REVIEWS]: true,
  [PossibleAction.CAN_NAVIGATE_AUDIT]: false,
  [PossibleAction.CAN_NAVIGATE_PIPELINES]: true,

  // AUDIT LOGGING
  [PossibleAction.CAN_GET_AUDIT]: false,

  // MODEL CONFIG
  [PossibleAction.CAN_GET_CONFIG_IDS]: true,
  [PossibleAction.CAN_GET_CONFIG]: true,
  [PossibleAction.CAN_SAVE_CONFIG]: false,
  [PossibleAction.CAN_UPDATE_CONFIG]: false,
  [PossibleAction.CAN_DELETE_CONFIG]: false,

  // MODEL
  [PossibleAction.CAN_GET_MODEL]: true,
  [PossibleAction.CAN_SAVE_MODEL]: false,
  [PossibleAction.CAN_UPDATE_MODEL]: false,
  [PossibleAction.CAN_DELETE_MODEL]: false,

  // MODEL ANALYSIS
  [PossibleAction.CAN_ANALYSE_MODEL]: false,

  // MODEL IMPORT/EXPORT
  [PossibleAction.CAN_IMPORT_MODEL]: false,
  [PossibleAction.CAN_EXPORT_MODEL]: false,

  // BACKGROUND IMAGE
  [PossibleAction.CAN_GET_IMAGE]: true,
  [PossibleAction.CAN_SAVE_IMAGE]: false,
  [PossibleAction.CAN_DELETE_IMAGE]: false,

  // MODEL SCOPE
  [PossibleAction.CAN_GET_SCOPE]: true,
  [PossibleAction.CAN_SAVE_SCOPE]: true,
  [PossibleAction.CAN_DELETE_SCOPE]: true,

  // PROJECT
  [PossibleAction.CAN_GET_PROJECT]: true,
  [PossibleAction.CAN_SAVE_PROJECT]: false,
  [PossibleAction.CAN_UPDATE_PROJECT]: false,
  [PossibleAction.CAN_DELETE_PROJECT]: false,

  // REVIEW ASSIGNMENT
  [PossibleAction.CAN_GET_ASSIGNMENT_FOR_EDITOR]: false,
  [PossibleAction.CAN_GET_ASSIGNMENT]: true,
  [PossibleAction.CAN_SAVE_ASSIGNMENT]: true,
  [PossibleAction.CAN_UPDATE_ASSIGNMENT]: true,
  [PossibleAction.CAN_DELETE_ASSIGNMENT]: true,
  [PossibleAction.CAN_EXPORT_ASSIGNMENT]: true,

  // REVIEW
  [PossibleAction.CAN_GET_REVIEW]: true,
  [PossibleAction.CAN_SAVE_REVIEW]: false,
  [PossibleAction.CAN_UPDATE_REVIEW]: false,
  [PossibleAction.CAN_DELETE_REVIEW]: true,

  // TAGS
  [PossibleAction.CAN_GET_TAG]: true,
  [PossibleAction.CAN_SAVE_TAG]: false,
  [PossibleAction.CAN_DELETE_TAG]: false,

  // USER INFO
  [PossibleAction.CAN_GET_USER_INFO]: true,

  // USER TAGS
  [PossibleAction.CAN_GET_USER_TAGS]: false,
  [PossibleAction.CAN_SAVE_USER_TAGS]: false,
  [PossibleAction.CAN_UPDATE_USER_TAGS]: false,
  [PossibleAction.CAN_DELETE_USER_TAGS]: false,

  // PIPELINES
  [PossibleAction.CAN_RUN_PIPELINE]: true,
};

const authMatrixReviewer: Record<string, boolean> = {
  // NAVIGATIONS
  [PossibleAction.CAN_NAVIGATE_ADMINISTRATION]: false,
  [PossibleAction.CAN_NAVIGATE_TAGS]: false,
  [PossibleAction.CAN_NAVIGATE_PROJECTS]: true,
  [PossibleAction.CAN_NAVIGATE_PROJECT_DETAILS]: true,
  [PossibleAction.CAN_NAVIGATE_MODEL_EDITOR]: false,
  [PossibleAction.CAN_NAVIGATE_SCOPES]: true,
  [PossibleAction.CAN_NAVIGATE_ASSIGNMENT_CREATION]: false,
  [PossibleAction.CAN_NAVIGATE_ASSIGNMENT_DETAILS]: true,
  [PossibleAction.CAN_NAVIGATE_REVIEW_PROCESS]: true,
  [PossibleAction.CAN_NAVIGATE_CONFIGS]: false,
  [PossibleAction.CAN_NAVIGATE_CONFIG_DETAILS]: false,
  [PossibleAction.CAN_NAVIGATE_REVIEWS]: false,
  [PossibleAction.CAN_NAVIGATE_AUDIT]: false,
  [PossibleAction.CAN_NAVIGATE_PIPELINES]: true,

  // AUDIT LOGGING
  [PossibleAction.CAN_GET_AUDIT]: false,

  // MODEL CONFIG
  [PossibleAction.CAN_GET_CONFIG_IDS]: true,
  [PossibleAction.CAN_GET_CONFIG]: true,
  [PossibleAction.CAN_SAVE_CONFIG]: false,
  [PossibleAction.CAN_UPDATE_CONFIG]: false,
  [PossibleAction.CAN_DELETE_CONFIG]: false,

  // MODEL
  [PossibleAction.CAN_GET_MODEL]: true,
  [PossibleAction.CAN_SAVE_MODEL]: false,
  [PossibleAction.CAN_UPDATE_MODEL]: false,
  [PossibleAction.CAN_DELETE_MODEL]: false,

  // MODEL ANALYSIS
  [PossibleAction.CAN_ANALYSE_MODEL]: false,

  // MODEL IMPORT/EXPORT
  [PossibleAction.CAN_IMPORT_MODEL]: false,
  [PossibleAction.CAN_EXPORT_MODEL]: false,

  // BACKGROUND IMAGE
  [PossibleAction.CAN_GET_IMAGE]: true,
  [PossibleAction.CAN_SAVE_IMAGE]: false,
  [PossibleAction.CAN_DELETE_IMAGE]: false,

  // MODEL SCOPE
  [PossibleAction.CAN_GET_SCOPE]: true,
  [PossibleAction.CAN_SAVE_SCOPE]: false,
  [PossibleAction.CAN_DELETE_SCOPE]: false,

  // PROJECT
  [PossibleAction.CAN_GET_PROJECT]: true,
  [PossibleAction.CAN_SAVE_PROJECT]: false,
  [PossibleAction.CAN_UPDATE_PROJECT]: false,
  [PossibleAction.CAN_DELETE_PROJECT]: false,

  // REVIEW ASSIGNMENT
  [PossibleAction.CAN_GET_ASSIGNMENT_FOR_EDITOR]: false,
  [PossibleAction.CAN_GET_ASSIGNMENT]: true,
  [PossibleAction.CAN_SAVE_ASSIGNMENT]: false,
  [PossibleAction.CAN_UPDATE_ASSIGNMENT]: false,
  [PossibleAction.CAN_DELETE_ASSIGNMENT]: false,
  [PossibleAction.CAN_EXPORT_ASSIGNMENT]: true,

  // REVIEW
  [PossibleAction.CAN_GET_REVIEW]: true,
  [PossibleAction.CAN_SAVE_REVIEW]: true,
  [PossibleAction.CAN_UPDATE_REVIEW]: true,
  [PossibleAction.CAN_DELETE_REVIEW]: false,

  // TAGS
  [PossibleAction.CAN_GET_TAG]: false,
  [PossibleAction.CAN_SAVE_TAG]: false,
  [PossibleAction.CAN_DELETE_TAG]: false,

  // USER INFO
  [PossibleAction.CAN_GET_USER_INFO]: true,

  // USER TAGS
  [PossibleAction.CAN_GET_USER_TAGS]: false,
  [PossibleAction.CAN_SAVE_USER_TAGS]: false,
  [PossibleAction.CAN_UPDATE_USER_TAGS]: false,
  [PossibleAction.CAN_DELETE_USER_TAGS]: false,

  // PIPELINES
  [PossibleAction.CAN_RUN_PIPELINE]: false,
};

export { authMatrixAdmin, authMatrixDesigner, authMatrixConfigurator, authMatrixReviewer };
