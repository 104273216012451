import { JsonProperty, Serializable } from 'typescript-json-serializer';
import {
  deserializeAssignmentState,
  deserializePriority,
  deserializeReviewMethod,
  serializeEnum,
} from '@/serializer/EnumHelpers';
import { PriorityEnum } from '@/enums/PriorityEnum';
import { ReviewMethodEnum } from '@/enums/ReviewMethodEnum';
import { AssignmentStateEnum } from '@/enums/AssignmentStateEnum';

@Serializable()
export default class ReviewAssignment {
  @JsonProperty('id')
  private readonly _id?: number;
  @JsonProperty('name')
  private _name: string;
  @JsonProperty('taskDescription')
  private _taskDescription: string;
  @JsonProperty('methodInstructions')
  private _methodInstructions: string;
  @JsonProperty('materials')
  private _materials: string;
  @JsonProperty({
    name: 'priority',
    onSerialize: serializeEnum,
    onDeserialize: deserializePriority,
  })
  private _priority: PriorityEnum;
  @JsonProperty('targetReviewCount')
  private _targetReviewCount: number;
  @JsonProperty({
    name: 'reviewMethod',
    onSerialize: serializeEnum,
    onDeserialize: deserializeReviewMethod,
  })
  private _reviewMethod: ReviewMethodEnum;
  @JsonProperty({
    name: 'state',
    onSerialize: serializeEnum,
    onDeserialize: deserializeAssignmentState,
  })
  private _state: AssignmentStateEnum;
  @JsonProperty('created')
  protected _created?: Date;
  @JsonProperty('modified')
  protected _modified?: Date;
  @JsonProperty('modelScopeId')
  private _modelScopeId?: string;
  @JsonProperty('tagIds')
  private _tagIds: number[] = [];
  @JsonProperty('projectId')
  private _projectId?: number;
  @JsonProperty('owners')
  private _owners: string[] = [];
  @JsonProperty('approved')
  private _approved = false;
  @JsonProperty('deleted')
  private _deleted = false;

  constructor(
    name: string,
    taskDescription: string,
    methodInstructions: string,
    materials: string,
    priority: PriorityEnum,
    targetReviewCount: number,
    reviewMethod: ReviewMethodEnum,
    reviewState: AssignmentStateEnum,
    modelScopeId?: string,
    projectId?: number
  ) {
    this._name = name;
    this._taskDescription = taskDescription;
    this._methodInstructions = methodInstructions;
    this._materials = materials;
    this._priority = priority;
    this._targetReviewCount = targetReviewCount;
    this._reviewMethod = reviewMethod;
    this._state = reviewState;
    this._modelScopeId = modelScopeId;
    this._projectId = projectId;
  }

  get id(): number | undefined {
    return this._id;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get taskDescription(): string {
    return this._taskDescription;
  }

  set taskDescription(value: string) {
    this._taskDescription = value;
  }

  get methodInstructions(): string {
    return this._methodInstructions;
  }

  set methodInstructions(value: string) {
    this._methodInstructions = value;
  }

  get materials(): string {
    return this._materials;
  }

  set materials(value: string) {
    this._materials = value;
  }

  get priority(): PriorityEnum {
    return this._priority;
  }

  set priority(value: PriorityEnum) {
    this._priority = value;
  }

  get targetReviewCount(): number {
    return this._targetReviewCount;
  }

  set targetReviewCount(value: number) {
    this._targetReviewCount = value;
  }

  get reviewMethod(): ReviewMethodEnum {
    return this._reviewMethod;
  }

  set reviewMethod(value: ReviewMethodEnum) {
    this._reviewMethod = value;
  }

  get state(): AssignmentStateEnum {
    return this._state;
  }

  set state(value: AssignmentStateEnum) {
    this._state = value;
  }

  get modelScopeId(): string | undefined {
    return this._modelScopeId;
  }

  set modelScopeId(value: string | undefined) {
    this._modelScopeId = value;
  }

  get tagIds(): number[] {
    return this._tagIds;
  }

  set tagIds(value: number[]) {
    this._tagIds = value;
  }

  get projectId(): number | undefined {
    return this._projectId;
  }

  set projectId(value: number | undefined) {
    this._projectId = value;
  }

  get owners(): string[] {
    return this._owners;
  }

  set owners(value: string[]) {
    this._owners = value;
  }

  get created(): Date | undefined {
    return this._created;
  }

  set created(value: Date | undefined) {
    this._created = value;
  }

  get modified(): Date | undefined {
    return this._modified;
  }

  set modified(value: Date | undefined) {
    this._modified = value;
  }

  get approved(): boolean {
    return this._approved;
  }

  set approved(value: boolean) {
    this._approved = value;
  }

  get deleted(): boolean {
    return this._deleted;
  }

  set deleted(value: boolean) {
    this._deleted = value;
  }

  get cardHeaderColor(): string {
    switch (this.state) {
      case AssignmentStateEnum.UNCLEAR:
      case AssignmentStateEnum.NOT_OK:
        return 'danger';
      case AssignmentStateEnum.OK:
        return 'success';
      default:
        return 'primary';
    }
  }
}
