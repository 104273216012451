import { JsonProperty, Serializable } from 'typescript-json-serializer';

@Serializable()
export default class EditorLayer {
  @JsonProperty('name')
  private _name: string;
  @JsonProperty('base64Name')
  private _base64Name: string;
  @JsonProperty('visible')
  private _visible = true;

  constructor(name: string) {
    this._name = name;
    this._base64Name = btoa(name);
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
    this._base64Name = btoa(value);
  }

  get base64Name(): string {
    return this._base64Name;
  }

  get visible(): boolean {
    return this._visible;
  }

  set visible(value: boolean) {
    this._visible = value;
  }
}
