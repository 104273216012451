import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import Model from '@/models/Model';
import ModelConfig from '@/models/ModelConfig';
import SelectedModelElement from '@/models/SelectedModelElement';
import ModelElement from '@/models/ModelElement';
import Edge from '@/models/Edge';
import ModelBackgroundImage from '@/models/ModelBackgroundImage';
import Node from '@/models/Node';
import EditorLayer from '@/models/EditorLayer';

@Module({
  namespaced: true,
})
/**
 * This vuex module holds the state of the Model Editor.
 */
export default class ModelEditorModule extends VuexModule {
  /**
   * Scaling of the svg content.
   * Must be a non-negative number.
   * e.g. 1 => 100%, 1.5 => 150%;
   */
  public scale = 1;
  /**
   * Standard size of wrapper container to allow scrolling.
   * Must be a non-negative number.
   * 200 => 200% width of parent
   */
  public baseSize = 100;
  /**
   * Array of SVGElements that are currently selected in Editor
   */
  public selectedElements: SelectedModelElement[] = [];
  public selectedGroup: string | boolean = false;

  /**
   * Current loaded model
   */
  public currentModel?: Model = undefined;
  /**
   * Model config that belongs to the currently loaded model.
   */
  public currentConfig?: ModelConfig = undefined;

  /**
   * Object of background image that is assigned to the current model.
   */
  public currentModelBackgroundImage?: ModelBackgroundImage = undefined;

  public editorLayers: EditorLayer[] = [];

  /**
   * Changes the scaling of the svg content
   * @param scale non-negative number
   */
  @Mutation
  public mutateScale(scale: number): void {
    this.scale = scale;
  }

  /**
   * Action that calls the mutation to change scaling
   * @param scale
   */
  @Action({ commit: 'mutateScale' })
  public setScale(scale: number): number {
    return scale;
  }

  /**
   * Changes the background image of the current model
   * @param backgroundImage ModelBackgroundImage Object with href and position
   */
  @Mutation
  public mutateCurrentModelBackgroundImage(backgroundImage: ModelBackgroundImage | undefined): void {
    this.currentModelBackgroundImage = backgroundImage;
  }

  /**
   * Action that calls the mutation to change the background image of current model
   * @param backgroundImage ModelBackgroundImage Object with href and position
   */
  @Action({ commit: 'mutateCurrentModelBackgroundImage' })
  public setCurrentModelBackgroundImage(
    backgroundImage: ModelBackgroundImage | undefined
  ): ModelBackgroundImage | undefined {
    return backgroundImage;
  }

  @Mutation
  public mutateSelectedGroup(group: string | boolean): void {
    this.selectedGroup = group;
  }

  @Action({ commit: 'mutateSelectedGroup' })
  public setSelectedGroup(group: string | boolean): string | boolean {
    return group;
  }

  /**
   * Replaces the selected elements with the given array of selected elements.
   * @param selectedElements array of selected elements
   */
  @Mutation
  public mutateSelectedElements(selectedElements: SelectedModelElement[]): void {
    this.selectedElements = selectedElements;
  }

  @Mutation
  public mutateAddSelectedElement(selectedElement: SelectedModelElement): void {
    this.selectedElements.push(selectedElement);
  }

  /**
   * Calls mutation to change selected elements. Action does not change payload.
   * @param selectedElements array of selected elements
   */
  @Action({ commit: 'mutateSelectedElements' })
  public setSelectedElements(selectedElements: SelectedModelElement[]): SelectedModelElement[] {
    return selectedElements;
  }

  @Action({ commit: 'mutateAddSelectedElement' })
  public addSelectedElement(selectedElement: SelectedModelElement): SelectedModelElement {
    return selectedElement;
  }

  @Action({ commit: 'mutateSelectedElements' })
  public clearSelectedElements(): SelectedModelElement[] {
    return [];
  }

  /**
   * Changes the current model to the given one
   * @param model new current model
   */
  @Mutation
  public mutateCurrentModel(model: Model | undefined): void {
    this.currentModel = model;
  }

  /**
   * Calls mutation to change current model to the given one. Action does not change payload.
   * @param model new current model
   */
  @Action({ commit: 'mutateCurrentModel' })
  public setCurrentModel(model: Model | undefined): Model | undefined {
    return model;
  }

  /**
   * Changes the current config to the given one
   * @param config new current config.
   */
  @Mutation
  public mutateCurrentConfig(config: ModelConfig): void {
    this.currentConfig = config;
  }

  /**
   * Calls mutation to change current config to the given one. Action does not change payload.
   * @param config new current config
   */
  @Action({ commit: 'mutateCurrentConfig' })
  public setCurrentConfig(config: ModelConfig | undefined): ModelConfig | undefined {
    return config;
  }

  @Mutation
  public addNodeToCurrentModelMutation(node: Node): void {
    if (this.currentModel) {
      this.currentModel.nodes.push(node);
    }
  }

  @Action({ commit: 'addNodeToCurrentModelMutation' })
  public addNodeToCurrentModel(node: ModelElement): ModelElement {
    return node;
  }

  @Mutation
  public addEdgeToCurrentModelMutation(edge: Edge): void {
    if (this.currentModel) {
      this.currentModel.edges.push(edge);
    }
  }

  @Action({ commit: 'addEdgeToCurrentModelMutation' })
  public addEdgeToCurrentModel(edge: Edge): Edge {
    return edge;
  }

  @Mutation
  public mutateEditorLayers(editorLayers: EditorLayer[]): void {
    this.editorLayers = editorLayers;
  }

  @Action({ commit: 'mutateEditorLayers' })
  public setEditorLayers(editorLayers: EditorLayer[]): EditorLayer[] {
    return editorLayers;
  }

  get selectedElementIds(): string[] {
    return this.selectedElements.map((selectedElement) => selectedElement.modelElement.id);
  }
}
