import { PossibleAction } from '@/auth/PossibleAction';

const authMatrixProjectOwner: Record<string, boolean> = {
  // MODEL
  [PossibleAction.CAN_GET_MODEL]: true,
  [PossibleAction.CAN_SAVE_MODEL]: true,
  [PossibleAction.CAN_UPDATE_MODEL]: true,
  [PossibleAction.CAN_DELETE_MODEL]: true,

  // MODEL ANALYSIS
  [PossibleAction.CAN_ANALYSE_MODEL]: true,

  // MODEL IMPORT/EXPORT
  [PossibleAction.CAN_IMPORT_MODEL]: true,
  [PossibleAction.CAN_EXPORT_MODEL]: true,

  // BACKGROUND IMAGE
  [PossibleAction.CAN_GET_IMAGE]: true,
  [PossibleAction.CAN_SAVE_IMAGE]: true,
  [PossibleAction.CAN_DELETE_IMAGE]: true,

  // MODEL SCOPE
  [PossibleAction.CAN_GET_SCOPE]: true,
  [PossibleAction.CAN_SAVE_SCOPE]: true,
  [PossibleAction.CAN_DELETE_SCOPE]: true,

  // PROJECT
  [PossibleAction.CAN_GET_PROJECT]: true,
  [PossibleAction.CAN_SAVE_PROJECT]: true,
  [PossibleAction.CAN_UPDATE_PROJECT]: true,
  [PossibleAction.CAN_DELETE_PROJECT]: true,

  // REVIEW ASSIGNMENT
  [PossibleAction.CAN_GET_ASSIGNMENT]: true,
  [PossibleAction.CAN_SAVE_ASSIGNMENT]: true,
  [PossibleAction.CAN_UPDATE_ASSIGNMENT]: true,
  [PossibleAction.CAN_DELETE_ASSIGNMENT]: true,

  // REVIEW
  [PossibleAction.CAN_GET_REVIEW]: true,
  [PossibleAction.CAN_SAVE_REVIEW]: true,
  [PossibleAction.CAN_UPDATE_REVIEW]: true,
  [PossibleAction.CAN_DELETE_REVIEW]: true,
};

const authMatrixProjectMember: Record<string, boolean> = {
  // MODEL
  [PossibleAction.CAN_GET_MODEL]: true,
  [PossibleAction.CAN_SAVE_MODEL]: true,
  [PossibleAction.CAN_UPDATE_MODEL]: true,
  [PossibleAction.CAN_DELETE_MODEL]: true,

  // MODEL ANALYSIS
  [PossibleAction.CAN_ANALYSE_MODEL]: true,

  // MODEL IMPORT/EXPORT
  [PossibleAction.CAN_IMPORT_MODEL]: true,
  [PossibleAction.CAN_EXPORT_MODEL]: true,

  // BACKGROUND IMAGE
  [PossibleAction.CAN_GET_IMAGE]: true,
  [PossibleAction.CAN_SAVE_IMAGE]: true,
  [PossibleAction.CAN_DELETE_IMAGE]: true,

  // MODEL SCOPE
  [PossibleAction.CAN_GET_SCOPE]: true,
  [PossibleAction.CAN_SAVE_SCOPE]: true,
  [PossibleAction.CAN_DELETE_SCOPE]: true,

  // PROJECT
  [PossibleAction.CAN_GET_PROJECT]: true,
  [PossibleAction.CAN_SAVE_PROJECT]: true,
  [PossibleAction.CAN_UPDATE_PROJECT]: false,
  [PossibleAction.CAN_DELETE_PROJECT]: false,

  // REVIEW ASSIGNMENT
  [PossibleAction.CAN_GET_ASSIGNMENT]: true,
  [PossibleAction.CAN_SAVE_ASSIGNMENT]: true,
  [PossibleAction.CAN_UPDATE_ASSIGNMENT]: true,
  [PossibleAction.CAN_DELETE_ASSIGNMENT]: true,

  // REVIEW
  [PossibleAction.CAN_GET_REVIEW]: true,
  [PossibleAction.CAN_SAVE_REVIEW]: true,
  [PossibleAction.CAN_UPDATE_REVIEW]: true,
  [PossibleAction.CAN_DELETE_REVIEW]: true,
};

export { authMatrixProjectOwner, authMatrixProjectMember };
