import Point from '@/models/Point';

import { JsonProperty, Serializable } from 'typescript-json-serializer';

@Serializable()
export default class AllowedConnection {
  @JsonProperty('from')
  private readonly _from: string; // typeName of NodeType it can connect from
  @JsonProperty({
    name: 'fromAllowedConnectionPoints',
    type: Point,
  })
  private readonly _fromAllowedConnectionPoints: Point[];
  @JsonProperty('to')
  private readonly _to: string; // typeName of NodeType it can connect to
  @JsonProperty({
    name: 'toAllowedConnectionPoints',
    type: Point,
  })
  private readonly _toAllowedConnectionPoints: Point[];

  constructor(
    from: string,
    to: string,
    fromAllowedConnectionPoints: Point[] = [],
    toAllowedConnectionPoints: Point[] = []
  ) {
    this._from = from;
    this._to = to;
    this._fromAllowedConnectionPoints = fromAllowedConnectionPoints;
    this._toAllowedConnectionPoints = toAllowedConnectionPoints;
  }

  get from(): string {
    return this._from;
  }

  get to(): string {
    return this._to;
  }

  get fromAllowedConnectionPoints(): Point[] {
    return this._fromAllowedConnectionPoints;
  }

  get toAllowedConnectionPoints(): Point[] {
    return this._toAllowedConnectionPoints;
  }

  public isFromPointAllowed(from: Point): boolean {
    if (this._fromAllowedConnectionPoints.length > 0) {
      return (
        this._fromAllowedConnectionPoints.findIndex((p) => {
          return p.x === from.x && p.y === from.y;
        }) > -1
      );
    }
    return true;
  }

  public isToPointAllowed(to: Point): boolean {
    if (this._toAllowedConnectionPoints.length > 0) {
      return (
        this._toAllowedConnectionPoints.findIndex((p) => {
          return p.x === to.x && p.y === to.y;
        }) > -1
      );
    }
    return true;
  }
}
