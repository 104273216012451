import _Vue from 'vue';
import ModelDifference from '@/views/model-difference/ModelDifference.vue';
import Feature from '@/features/Feature';
import { Features } from '@/enums/Features';
import { hasPermission } from '@/auth/AuthService';
import { PossibleAction } from '@/auth/PossibleAction';

export default function ModelDifferencePlugin(Vue: typeof _Vue, options: any): void {
  // bail out if feature is not active
  if (!Feature.isEnabled(Features.MODEL_DIFFERENCE)) {
    return;
  }

  const routes = [
    {
      path: '/project/details/:projectId/model-difference/:modelIds+',
      name: 'modelDifference',
      props: true,
      component: () => import('./views/ModelDifference.vue'),
      meta: {
        title: 'Model Difference',
      },
      beforeEnter(to, from, next) {
        const permission: boolean | null = hasPermission(PossibleAction.CAN_NAVIGATE_PROJECT_DETAILS);

        if (typeof to.params.modelIds === 'string') {
          to.params.modelIds = to.params.modelIds.split('/');
        }

        if (permission) {
          next();
        } else if (permission === null) {
          // no token
          next({ name: 'login' });
        } else {
          next({ path: from.path });
        }
      },
    },
  ];

  if (Vue.prototype.$additionalRoutes === undefined) {
    Vue.prototype.$additionalRoutes = [];
  }

  // add routes
  Vue.prototype.$additionalRoutes = Vue.prototype.$additionalRoutes.concat(routes);
}
