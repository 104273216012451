import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import VueDragscroll from 'vue-dragscroll';
import AreaDrawableSvgComponent from '@/components/Editor/drawables/AreaDrawableSvgComponent.vue';
import TextAnnotationDrawableSvgComponent from '@/components/Editor/drawables/TextAnnotationDrawableSvgComponent.vue';
import Multiselect from 'vue-multiselect';
import MarkerDrawableSvgComponent from '@/components/Editor/drawables/MarkerDrawableSvgComponent.vue';
import BlueprintPlugin from '@/plugin-blueprint/BlueprintPlugin';
import ModelDifferencePlugin from '@/model-difference/ModelDifferencePlugin';

Vue.config.productionTip = false;
Vue.prototype.$additionalRoutes = [];

// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

// Scroll in container
Vue.use(VueDragscroll);

Vue.component('area-drawable-svg-component', AreaDrawableSvgComponent);
Vue.component('text-annotation-drawable-svg-component', TextAnnotationDrawableSvgComponent);
Vue.component('marker-drawable-svg-component', MarkerDrawableSvgComponent);

Vue.component('multiselect', Multiselect);

// register Plugins here
Vue.use(BlueprintPlugin);
Vue.use(ModelDifferencePlugin);

// register plugins before this line
router.addRoutes(Vue.prototype.$additionalRoutes);

const app = new Vue({
  router,
  store,
  render: (h) => h(App),
  data: {
    loading: false,
  },
}).$mount('#app');

export default app;
