import MainService from '@/services/MainService';
import { deserialize, serialize } from 'typescript-json-serializer';
import ReviewAssignment from '@/models/reviews/ReviewAssignment';
import StoredReviewAssignment from '@/models/reviews/StoredReviewAssignment';
import { AssignmentStateEnum } from '@/enums/AssignmentStateEnum';
import { AxiosResponse } from 'axios';
import Review from '@/models/reviews/Review';

export default class ReviewAssignmentService {
  private static mainURL = '/reviewAssignment';

  public static getAll(): Promise<ReviewAssignment[]> {
    return MainService.get(this.mainURL).then((response) => {
      return response.data.map((item) => deserialize(item, ReviewAssignment));
    });
  }

  public static getAllByOwnerID(owner: string): Promise<ReviewAssignment[]> {
    return MainService.get(this.mainURL, { params: { owner } }).then((response) => {
      return response.data.map((item) => deserialize(item, ReviewAssignment));
    });
  }

  public static getAllByProjectID(projectId: number): Promise<ReviewAssignment[]> {
    return MainService.get(this.mainURL, { params: { projectId } }).then((response) => {
      return response.data.map((item) => deserialize(item, ReviewAssignment));
    });
  }

  public static getAllByState(state: AssignmentStateEnum): Promise<ReviewAssignment[]> {
    return MainService.get(this.mainURL, { params: { state: state.toString() } }).then((response) => {
      return response.data.map((item) => deserialize(item, ReviewAssignment));
    });
  }

  public static getAllByModelScopeId(projectId: number, modelScope: string): Promise<ReviewAssignment[]> {
    return MainService.get(this.mainURL, { params: { projectId, modelScope } }).then((response) => {
      return response.data.map((item) => deserialize(item, ReviewAssignment));
    });
  }

  public static getById(id: number): Promise<StoredReviewAssignment> {
    return MainService.get(this.mainURL + '/' + id).then((response) => {
      return deserialize(response.data, StoredReviewAssignment);
    });
  }

  public static getDTOById(id: number): Promise<ReviewAssignment> {
    return MainService.get(this.mainURL + '/' + id + '/slim').then((response) => {
      return deserialize(response.data, ReviewAssignment);
    });
  }

  public static save(reviewAssignment: ReviewAssignment): Promise<StoredReviewAssignment> {
    return MainService.post(this.mainURL, JSON.stringify(serialize(reviewAssignment))).then((response) => {
      return deserialize(response.data, StoredReviewAssignment);
    });
  }

  public static overrideState(id: number, approvedState: AssignmentStateEnum): Promise<ReviewAssignment> {
    let state;
    switch (approvedState) {
      case AssignmentStateEnum.IN_PROCESS:
        state = 'IN_PROCESS';
        break;
      case AssignmentStateEnum.NOT_OK:
        state = 'NOT_OK';
        break;
      case AssignmentStateEnum.OK:
        state = 'OK';
        break;
      case AssignmentStateEnum.UNCLEAR:
        state = 'UNCLEAR';
        break;
      default:
        state = '';
    }
    return MainService.patch(this.mainURL + '/' + id + '/', { approvedState: state }).then((response) => {
      return deserialize(response.data, ReviewAssignment);
    });
  }

  public static delete(id: number): Promise<AxiosResponse> {
    return MainService.delete(this.mainURL + '/' + id + '/');
  }
}
