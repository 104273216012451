import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import ReviewAssignment from '@/models/reviews/ReviewAssignment';
import ModelScope from '@/models/ModelScope';

@Module({
  namespaced: true,
})
/**
 * Contains necessary data to draw connections between nodes
 */
export default class ModelEditorReviewStates extends VuexModule {
  private modelId?: string = undefined;
  private reviewAssignments: ReviewAssignment[] = [];
  private modelScopes: ModelScope[] = [];

  @Mutation
  public mutateModelId(modelId: string | undefined): void {
    this.modelId = modelId;
  }

  @Mutation
  public mutateSetReviewAssignments(reviewAssignments: ReviewAssignment[]): void {
    this.reviewAssignments = reviewAssignments;
  }

  @Mutation
  public mutateSetModelScopes(modelScopes: ModelScope[]): void {
    this.modelScopes = modelScopes;
  }

  @Action({ commit: 'mutateModelId' })
  public setModelId(modelId: string | undefined): string | undefined {
    return modelId;
  }

  @Action({ commit: 'mutateSetReviewAssignments' })
  public setReviewAssignments(reviewAssignments: ReviewAssignment[]): ReviewAssignment[] {
    return reviewAssignments;
  }

  @Action({ commit: 'mutateSetModelScopes' })
  public setModelScopes(modelScopes: ModelScope[]): ModelScope[] {
    return modelScopes;
  }

  get modelScopesWithModelElementIds(): Record<string, string[]> {
    const modelScopes = {};

    this.modelScopes.forEach((modelScope) => {
      if (modelScope.id) {
        if (!Object.prototype.hasOwnProperty.call(modelScopes, modelScope.id)) {
          modelScopes[modelScope.id] = [];
        }
        modelScope.nodeIds.forEach((nodeID) => {
          if (
            modelScope.id &&
            Object.prototype.hasOwnProperty.call(modelScopes, modelScope.id) &&
            modelScopes[modelScope.id].indexOf(nodeID) === -1
          ) {
            modelScopes[modelScope.id].push(nodeID);
          }
        });
        modelScope.edgeIds.forEach((edgeId) => {
          if (
            modelScope.id &&
            Object.prototype.hasOwnProperty.call(modelScopes, modelScope.id) &&
            modelScopes[modelScope.id].indexOf(edgeId) === -1
          ) {
            modelScopes[modelScope.id].push(edgeId);
          }
        });
        modelScope.drawableIds.forEach((drawableId) => {
          if (
            modelScope.id &&
            Object.prototype.hasOwnProperty.call(modelScopes, modelScope.id) &&
            modelScopes[modelScope.id].indexOf(drawableId) === -1
          ) {
            modelScopes[modelScope.id].push(drawableId);
          }
        });
      }
    });

    return modelScopes;
  }

  get modelElementIdsWithReviewAssignments(): Record<string, ReviewAssignment[]> {
    const reviewAssignments = {};
    const modelScopes = this.modelScopesWithModelElementIds;

    this.reviewAssignments.forEach((reviewAssignment) => {
      if (
        reviewAssignment.modelScopeId &&
        Object.prototype.hasOwnProperty.call(modelScopes, reviewAssignment.modelScopeId)
      ) {
        modelScopes[reviewAssignment.modelScopeId].forEach((nodeId) => {
          // Add nodeId as key
          if (!Object.prototype.hasOwnProperty.call(reviewAssignments, nodeId)) {
            reviewAssignments[nodeId] = [];
          }

          // add reviewAssignment to each node
          if (reviewAssignments[nodeId].indexOf(reviewAssignment) === -1) {
            reviewAssignments[nodeId].push(reviewAssignment);
          }
        });
      }
    });

    return reviewAssignments;
  }

  @Action
  public getReviewAssignmentsOfNodeByModelElementId(modelElementId: string): ReviewAssignment[] {
    if (Object.prototype.hasOwnProperty.call(this.modelElementIdsWithReviewAssignments, modelElementId)) {
      return this.modelElementIdsWithReviewAssignments[modelElementId];
    }

    return [];
  }
}
