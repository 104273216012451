




import { Prop, Vue } from 'vue-property-decorator';
import ModelElement from '@/models/ModelElement';
import SVGSelectEvent from '@/models/SVGSelectEvent';
import Attribute from '@/models/Attribute';
import { v4 as uuidv4 } from 'uuid';
import { mapState } from 'vuex';
import Component from 'vue-class-component';

@Component({
  computed: mapState('modelEditor', ['scale']),
})
/**
 * Wraps a modelElement into an svg group.
 */
export default class BaseSvgComponent extends Vue {
  @Prop({
    default: () => {
      return {};
    },
    required: true,
  })
  protected modelElement?: ModelElement;

  @Prop({
    default: () => {
      return [];
    },
    required: false,
  })
  protected connectedElements?: ModelElement[];

  protected internalID = uuidv4();

  constructor() {
    super();
  }

  get elementContent(): Attribute[] {
    if (this.modelElement) {
      return this.modelElement.attributes;
    }
    return [];
  }

  public $refs!: Vue['$refs'] & {
    svg_group: SVGElement;
  };

  public handleClick(event: MouseEvent): void {
    event.stopPropagation();
    this.$emit('svg-select', new SVGSelectEvent(event));
  }
}
