import { ReviewAssignmentExportTypeEnum } from '@/enums/ReviewAssignmentExportTypeEnum';

export default class Config {
  public static DEBUG = process.env.NODE_ENV !== 'production';
  public static BACKEND_HOST =
    process.env.NODE_ENV === 'production' ? 'NGINX_REPLACE_BACKEND_HOST' : 'http://localhost:8080';
  public static EDITOR = {
    DEBUG_EDGE_CLICK_HELPERS: false,
    SELECTED_ELEMENT_DEFAULT_CONFIG: {
      strokeColor: '#79bbff',
      strokeWidth: 4,
    },
  };
  public static EXPORT = {
    STRIP_HTML_TAGS: {
      [ReviewAssignmentExportTypeEnum.PDF]: true,
      [ReviewAssignmentExportTypeEnum.CSV]: false,
    },
  };
  public static SHOW_INFO_TOASTS = process.env.NODE_ENV !== 'production';
}
