



































































































import { Component, Vue } from 'vue-property-decorator';
import Project from '@/models/Project';
import ProjectService from '@/services/ProjectService';
import ModelConfig from '@/models/ModelConfig';
import ModelConfigService from '@/services/ModelConfigService';
import ReviewAssignment from '@/models/reviews/ReviewAssignment';
import ReviewAssignmentService from '@/services/ReviewAssignmentService';
import ReviewService from '@/services/ReviewService';
import Review from '@/models/reviews/Review';
import CurrentUser from '@/models/users/CurrentUser';
import { Action, State } from 'vuex-class';
import UserInfo from '@/models/users/UserInfo';
import UserService from '@/services/UserService';
import { hasPermission } from '@/auth/AuthService';
import { PossibleAction } from '@/auth/PossibleAction';
import ModelScopeService from '@/services/ModelScopeService';
import { UserRoleEnum } from '@/enums/UserRoleEnum';
import { AssignmentStateEnum } from '@/enums/AssignmentStateEnum';
import jwtDecode from 'jwt-decode';
import ModelScope from '@/models/ModelScope';
import Tag from '@/models/users/Tag';
import TagService from '@/services/TagService';
import { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';
import { FeatureMixin } from '@/mixins/FeatureMixin';

@Component
export default class App extends mixins(Toasts, FeatureMixin) {
  protected readonly PossibleAction = PossibleAction;

  protected shouldBeVisible(action: PossibleAction): boolean {
    const permission = hasPermission(action);
    return permission != null && permission;
  }

  @Action('setAvailableUsers', { namespace: 'userManagement' })
  protected setAvailableUsers!: (value: UserInfo[]) => void;

  @Action('setAvailableTags', { namespace: 'userManagement' })
  protected setAvailableTags!: (value: Tag[]) => void;

  @State('availableTags', { namespace: 'userManagement' })
  protected availableTags!: Tag[];

  protected currentUser: CurrentUser | null = null;

  mounted(): void {
    this.$router.afterEach((to) => {
      if (to && to.meta && to.meta.title && to.meta.title.length > 0) {
        document.title = 'M D R E | ' + to.meta.title;
      } else {
        document.title = 'M D R E';
      }
    });

    this.$root.$on('delete-project', (args) => {
      if (hasPermission(PossibleAction.CAN_DELETE_PROJECT)) {
        this.deleteProject(args.project, args.target);
      } else {
        this.showToast('Action denied', 'You do not have the required rights.', 'danger');
      }
    });

    this.$root.$on('delete-config', (args) => {
      if (hasPermission(PossibleAction.CAN_DELETE_CONFIG)) {
        this.deleteConfig(args.config, args.target);
      } else {
        this.showToast('Action denied', 'You do not have the required rights.', 'danger');
      }
    });

    this.$root.$on('change-config-state', (args) => {
      if (hasPermission(PossibleAction.CAN_UPDATE_CONFIG)) {
        this.changeConfigState(args.config, args.active);
      } else {
        this.showToast('Action denied', 'You do not have the required rights.', 'danger');
      }
    });

    this.$root.$on('delete-assignment', (args) => {
      if (hasPermission(PossibleAction.CAN_DELETE_ASSIGNMENT)) {
        this.deleteAssignment(args.assignment, args.target);
      } else {
        this.showToast('Action denied', 'You do not have the required rights.', 'danger');
      }
    });

    this.$root.$on('update-assignment', (args) => {
      if (hasPermission(PossibleAction.CAN_UPDATE_ASSIGNMENT)) {
        this.updateAssignment(args.assignment, args.state, args.target);
      } else {
        this.showToast('Action denied', 'You do not have the required rights.', 'danger');
      }
    });

    this.$root.$on('start-review-process', (args) => {
      if (hasPermission(PossibleAction.CAN_SAVE_REVIEW)) {
        this.startReviewProcess(args.assignment, args.target);
      } else {
        this.showToast('Action denied', 'You do not have the required rights.', 'danger');
      }
    });

    this.$root.$on('resume-review-process', (args) => {
      if (hasPermission(PossibleAction.CAN_SAVE_REVIEW)) {
        this.resumeReviewProcess(args.assignment, args.review, args.target);
      } else {
        this.showToast('Action denied', 'You do not have the required rights.', 'danger');
      }
    });

    this.$root.$on('delete-scope', (args) => {
      this.deleteScope(args.scope, args.target);
    });

    this.$root.$on('user-change', (args) => {
      this.parseCurrentUser(args.token);
    });

    this.$root.$on('logout', () => {
      this.$bvToast.hide();
      this.$bvToast.toast('Your session is no longer valid.', {
        title: 'Session expired',
        variant: 'warning',
        solid: true,
        autoHideDelay: 10000,
      });
      this.logout();
    });

    this.parseCurrentUser(localStorage.getItem('mdre-token'));
  }

  protected showNavigation(): boolean {
    const name = this.$router.currentRoute.name;
    return (name && name.length > 0 && name !== 'login') as boolean;
  }

  protected parseCurrentUser(token: string | null): void {
    if (token) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
      const deToken: any = jwtDecode(token);
      const user = new CurrentUser(deToken.sub);
      user.name = deToken.fn;
      let roles: string = deToken.rol[0];
      roles = roles.substring(1, roles.length - 1);
      roles.split(', ').forEach((role) => user.roles.push(UserRoleEnum[role]));
      this.currentUser = user;

      this.loadAvailableUser();
    } else {
      this.currentUser = null;
    }
  }

  protected loadAvailableUser(): void {
    this.setLoading(true);
    UserService.getAllUserInfos()
      .then((userInfos) => {
        this.setAvailableUsers(userInfos);
        UserService.getById()
          .then((taggedUser) => {
            TagService.getAll()
              .then((tags) => {
                this.setAvailableTags(tags);
                if (this.currentUser) {
                  this.currentUser.tags = [];
                  taggedUser.tagIds.forEach((tagId) => {
                    const tag = this.availableTags.find((tag) => tag.id === tagId);
                    if (tag && this.currentUser) {
                      this.currentUser.tags.push(tag);
                    }
                  });
                }
              })
              .catch(() => {
                this.showToast('Failed to load', 'Could not load tags.', 'danger');
              });
          })
          .catch(() => {
            this.showToast('Failed to load', 'Could not load current user.', 'danger');
          })
          .finally(() => this.setLoading(false));
      })
      .catch(() => {
        this.setLoading(false);
        this.showToast('Failed to load', 'Could not load users.', 'danger');
      });
  }

  beforeDestroy(): void {
    this.$root.$off('delete-project');
    this.$root.$off('delete-config');
    this.$root.$off('change-config-state');
    this.$root.$off('delete-assignment');
    this.$root.$off('update-assignment');
    this.$root.$off('start-review-process');
    this.$root.$off('resume-review-process');
    this.$root.$off('delete-scope');
    this.$root.$off('user-change');
    this.$root.$off('logout');

    localStorage.removeItem('mdre-token');
  }

  protected logout(): void {
    localStorage.removeItem('mdre-token');
    this.$router.push('/login');
    this.setLoading(false);
  }

  protected changePassword(): void {
    this.$router.push('/selfservice');
    this.setLoading(false);
  }

  protected deleteProject(project: Project, target: Vue): void {
    this.$bvModal
      .msgBoxConfirm('Do you really want to delete this project?', {
        title: 'Delete Project: ' + project.name,
        okVariant: 'danger',
        okTitle: 'Delete',
        cancelTitle: 'Cancel',
        hideHeaderClose: true,
        centered: true,
      })
      .then((confirmationResponse) => {
        if (confirmationResponse && project.id) {
          this.setLoading(true);
          ProjectService.delete(project.id)
            .then(() => {
              this.showToast('Successfully deleted', "Project '" + project.name + "' successfully deleted.", 'success');
              target.$emit('finished-delete-project', { project });
            })
            .catch((backendError) => {
              let msg;
              if (backendError.response.status === 409) {
                msg = 'There are existing models or assignments in this project.';
              } else {
                msg = 'Could not delete project: ' + backendError.response.status;
              }
              this.showToast('Failed to delete', msg, 'danger');
            });
        }
      })
      .catch(() => {
        this.showToast('Unexpected failure', 'Oops, something failed...', 'danger');
      })
      .finally(() => this.setLoading(false));
  }

  protected deleteConfig(config: ModelConfig, target: Vue): void {
    this.$bvModal
      .msgBoxConfirm('Do you really want to delete this model config?', {
        title: 'Delete Config: ' + config.modelType + ' ' + config.version,
        okVariant: 'danger',
        okTitle: 'Delete',
        cancelTitle: 'Cancel',
        hideHeaderClose: true,
        centered: true,
      })
      .then((confirmationResponse) => {
        if (confirmationResponse) {
          this.setLoading(true);
          ModelConfigService.deleteByTypeAndVersion(config.modelType, config.version)
            .then(() => {
              this.showToast(
                'Successfully deleted',
                "'" + config.modelType + ' ' + config.version + "' successfully deleted.",
                'success'
              );
              target.$emit('finished-delete-config', { config });
            })
            .catch((backendError) => {
              let msg;
              if (backendError.response.status === 409) {
                msg = 'Model Config is used by existing model.';
              } else {
                msg = 'Could not delete config: ' + backendError.response.status;
              }
              this.showToast('Failed to delete', msg, 'danger');
            });
        }
      })
      .catch(() => {
        this.showToast('Unexpected failure', 'Oops, something failed...', 'danger');
      })
      .finally(() => this.setLoading(false));
  }

  protected changeConfigState(config: ModelConfig, active: boolean): void {
    this.$bvModal
      .msgBoxConfirm('Do you want to ' + (active ? 'activate' : 'deactivate') + ' this model config?', {
        title: (active ? 'Activate' : 'Deactivate') + ' Config: ' + config.modelType + ' ' + config.version,
        okVariant: active ? 'primary' : 'danger',
        okTitle: active ? 'Activate' : 'Deactivate',
        cancelTitle: 'Cancel',
        hideHeaderClose: true,
        centered: true,
      })
      .then((confirmationResponse) => {
        if (confirmationResponse) {
          this.setLoading(true);
          ModelConfigService.setActiveState(config, active)
            .then(() => {
              const msg =
                "'" +
                config.modelType +
                ' ' +
                config.version +
                "' successfully " +
                (active ? 'activated.' : 'deactivated.');
              this.showToast('Successfully ' + (active ? 'activated' : 'deactivated'), msg, 'success');
              config.active = active;
            })
            .catch((backendError) => {
              let msg;
              if (backendError.response.status === 404) {
                msg = 'Model Config does not exist.';
              } else {
                msg = 'Could not ' + (active ? 'activate' : 'deactivate') + ' config: ' + backendError.response.status;
              }
              this.showToast('Failed to ' + (active ? 'activate' : 'deactivate'), msg, 'danger');
            });
        }
      })
      .catch(() => {
        this.showToast('Unexpected failure', 'Oops, something failed...', 'danger');
      })
      .finally(() => this.setLoading(false));
  }

  protected deleteAssignment(assignment: ReviewAssignment, target: Vue): void {
    this.$bvModal
      .msgBoxConfirm('Do you really want to delete this assignment?', {
        title: 'Delete Assignment: ' + assignment.name,
        okVariant: 'danger',
        okTitle: 'Delete',
        cancelTitle: 'Cancel',
        hideHeaderClose: true,
        centered: true,
      })
      .then((confirmationResponse) => {
        if (confirmationResponse && assignment.id) {
          this.setLoading(true);
          ReviewAssignmentService.delete(assignment.id)
            .then(() => {
              this.showToast(
                'Successfully deleted',
                "Review Assignment '" + assignment.name + "' successfully deleted.",
                'success'
              );
              target.$emit('finished-delete-assignment', { assignment });
            })
            .catch((backendError) => {
              let msg;
              if (backendError.response.status === 409) {
                msg = 'There are existing reviews for this assignment.';
              } else {
                msg = 'Could not delete assignment: ' + backendError.response.status;
              }
              this.showToast('Failed to delete', msg, 'danger');
            });
        }
      })
      .catch(() => {
        this.showToast('Unexpected behaviour', 'Oops, something failed...', 'danger');
      })
      .finally(() => this.setLoading(false));
  }

  protected updateAssignment(assignment: ReviewAssignment, newState: AssignmentStateEnum, target: Vue): void {
    if (assignment.id) {
      this.setLoading(true);
      ReviewAssignmentService.overrideState(assignment.id, newState)
        .then(() => {
          this.showToast(
            'Successfully updated',
            "Review Assignment '" + assignment.name + "' successfully updated.",
            'success'
          );
          target.$emit('finished-update-assignment', { assignment, newState });
        })
        .catch((backendError) => {
          let msg;
          if (backendError.response.status === 400) {
            msg = 'The assignment state was already overridden by an owner.';
          } else {
            msg = 'Could not update assignment: ' + backendError.response.status;
          }
          this.showToast('Failed to update', msg, 'danger');
        })
        .finally(() => this.setLoading(false));
    }
  }

  protected resumeReviewProcess(assignment: ReviewAssignment, review: Review, target: Vue): void {
    if (assignment.state !== AssignmentStateEnum.IN_PROCESS) {
      this.showToast('Cannot resume review', 'Assignment is already finished', 'danger');
    } else {
      if (assignment.id && review.id && this.currentUser && this.currentUser.uid) {
        target.$emit('finished-review-creation', { review: review });
      }
    }
  }

  protected startReviewProcess(assignment: ReviewAssignment, target: Vue): void {
    if (assignment.state !== AssignmentStateEnum.IN_PROCESS) {
      this.showToast('Cannot start review', 'Assignment is already finished', 'danger');
    } else {
      this.$bvModal
        .msgBoxConfirm(
          "This will automatically create a review with the state 'In Review'. Do you want to create it?",
          {
            title: "Start Review for '" + assignment.name + "'",
            okVariant: 'primary',
            okTitle: 'Create',
            cancelTitle: 'Cancel',
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((confirmationResponse) => {
          if (confirmationResponse && assignment.id && this.currentUser && this.currentUser.uid) {
            this.setLoading(true);
            const reviewToSave = new Review(assignment.id, this.currentUser.uid);
            ReviewService.save(reviewToSave)
              .then((savedReview) => {
                this.showToast('Successfully created', 'Review is successfully created.', 'success');
                target.$emit('finished-review-creation', { review: savedReview });
              })
              .catch((backendError) => {
                let msg;
                if (backendError.response.status === 400) {
                  msg = 'You do not have the required tags for this assignment.';
                } else if (backendError.response.status === 409) {
                  msg = 'You have already started a review for ' + assignment.name + '.';
                } else {
                  msg = 'Could not create a review: ' + backendError.response.status;
                }
                this.showToast('Failed to create', msg, 'danger');
              });
          }
        })
        .catch(() => {
          this.showToast('Unexpected behaviour', 'Oops, something failed...', 'danger');
        })
        .finally(() => this.setLoading(false));
    }
  }

  protected deleteScope(
    scope: ModelScope,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
    target: any
  ): void {
    this.$bvModal
      .msgBoxConfirm('Do you really want to delete this model scope?', {
        title: 'Delete Model Scope',
        okVariant: 'danger',
        okTitle: 'Delete',
        cancelTitle: 'Cancel',
        hideHeaderClose: true,
        centered: true,
      })
      .then((confirmationResponse) => {
        if (confirmationResponse && scope.id) {
          this.setLoading(true);
          ModelScopeService.delete(scope.id)
            .then(() => {
              this.showToast('Successfully deleted', 'Model Scope was successfully deleted.', 'success');
              target.$emit('finished-delete-scope', { scope });
            })
            .catch((backendError) => {
              this.showToast(
                'Failed to delete',
                'Could not delete assignment: ' + backendError.response.status,
                'danger'
              );
            });
        }
      })
      .catch(() => {
        this.showToast('Unexpected behaviour', 'Oops, something failed...', 'danger');
      })
      .finally(() => this.setLoading(false));
  }

  protected setLoading(loading: boolean): void {
    this.$root.$data.loading = loading;
  }
}
