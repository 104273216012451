import { JsonProperty, Serializable } from 'typescript-json-serializer';

@Serializable()
export default class UserPWResetInput {
  @JsonProperty('secret')
  private _secret: string;
  @JsonProperty('newPassword')
  private _newPassword: string;

  constructor(secret: string, newPassword: string) {
    this._secret = secret;
    this._newPassword = newPassword;
  }

  get secret(): string {
    return this._secret;
  }

  set secret(value: string) {
    this._secret = value;
  }

  get newPassword(): string {
    return this._newPassword;
  }

  set newPassword(value: string) {
    this._newPassword = value;
  }
}
