


















import Component, { mixins } from 'vue-class-component';
import DrawableSvgComponent from '@/components/Editor/drawables/DrawableSvgComponent.vue';
import { Vue, Watch } from 'vue-property-decorator';
import Point from '@/models/Point';
import Position from '@/models/Position';
import Bounds from '@/models/Bounds';
import SVGSelectEvent from '@/models/SVGSelectEvent';
import MarkerDrawable from '@/models/drawables/MarkerDrawable';
import { State } from 'vuex-class';
import ModelConfig from '@/models/ModelConfig';
import MarkerState from '@/models/MarkerState';
import MarkerType from '@/models/MarkerType';
import { htmlEntities } from '@/serializer/helpers';
import EditorLayer from '@/models/EditorLayer';
import { LayersMixins } from '@/mixins/LayersMixins';

@Component({})
export default class MarkerDrawableSvgComponent extends mixins(LayersMixins, DrawableSvgComponent) {
  public $refs!: Vue['$refs'] & {
    svg_group: SVGElement;
  };

  get markerDrawable(): MarkerDrawable {
    return this.modelElement as MarkerDrawable;
  }

  get elementType(): MarkerType | undefined {
    return this.currentConfig.getMarkerType(this.markerDrawable.marker.type);
  }

  get layer(): EditorLayer | undefined {
    if (this.elementType && this.elementType.layer) {
      return this.getLayer(btoa(this.elementType.layer));
    }

    return undefined;
  }

  get visible(): boolean {
    if (this.layer) {
      return this.layer.visible;
    }

    return true;
  }

  mounted(): void {
    if (this.modelElement) {
      this.modelElement.target = this.internalID;
      this.modelElement.translateTarget = this.internalID;
      this.modelElement.bounds = this.getBounds();
      this.modelElement.position = this.markerDrawable.marker.position;
    }
  }

  @Watch('modelElement.position.startPos')
  protected handleStartPosChanged(newVal: Point): void {
    if (this.modelElement) {
      this.markerDrawable.marker.position.endPos = new Point(
        newVal.x + this.modelElement.bounds.width,
        newVal.y + this.modelElement.bounds.height
      );
    }
    this.markerDrawable.marker.position.startPos = newVal;
  }

  @Watch('modelElement.bounds')
  protected handleBoundsChanged(newVal: Bounds): void {
    this.markerDrawable.marker.position.endPos = new Point(
      this.markerDrawable.marker.position.startPos.x + newVal.width,
      this.markerDrawable.marker.position.startPos.y + newVal.height
    );
  }

  @Watch('modelElement.position.endPos')
  protected handleEndPosChanged(newVal: Point): void {
    Vue.set(this.markerDrawable.marker.position, 'endPos', newVal);
  }

  protected getBounds(): Bounds {
    return new Bounds(this.width, this.height);
  }

  public handleMouseDown(event: MouseEvent): void {
    this.$emit('svg-select-down', new SVGSelectEvent(event, true));
  }

  public handleMouseUp(event: MouseEvent): void {
    this.$emit('svg-select-up', new SVGSelectEvent(event));
  }

  @Watch('modelElement.target')
  protected handleTargetChange(newVal: string): void {
    if (!newVal && this.modelElement) {
      this.modelElement.target = this.internalID;
      this.modelElement.translateTarget = this.internalID;
    }
  }

  get style(): { transform?: string } {
    if (this.modelElement) {
      return {
        transform:
          'translate(' +
          this.markerDrawable.marker.position.startPos.x +
          'px, ' +
          this.markerDrawable.marker.position.startPos.y +
          'px)',
      };
    }

    return {};
  }

  get width(): number {
    if (this.modelElement) {
      return Math.abs(this.markerDrawable.marker.position.startPos.x - this.markerDrawable.marker.position.endPos.x);
    }

    return 100;
  }

  get height(): number {
    if (this.modelElement) {
      return Math.abs(this.markerDrawable.marker.position.startPos.y - this.markerDrawable.marker.position.endPos.y);
    }

    return 100;
  }

  getDisplayFromMarkerState(type: string, state: string): MarkerState | undefined {
    let marker = this.currentConfig.markerTypes.find((marker: MarkerType) => {
      return marker.typeName === type;
    });

    return marker?.markerStates.find((ms: MarkerState) => {
      return ms.stateName === state;
    });
  }

  getDisplayStringWithReplacedText(markerState: MarkerState): string {
    const regexName = new RegExp('{{name}}', 'g');
    if (this.modelElement?.name != null) {
      return markerState.display.replace(regexName, htmlEntities(this.modelElement?.name));
    }
    return markerState.display;
  }
}
