import { JsonProperty, Serializable } from 'typescript-json-serializer';
import { ReviewStateEnum } from '@/enums/ReviewStateEnum';

@Serializable()
export default class Review {
  @JsonProperty('id')
  private _id?: number;
  @JsonProperty('state')
  private _state: ReviewStateEnum;
  @JsonProperty('comment')
  private _comment: string | null = null;
  @JsonProperty('created')
  private _created?: Date;
  @JsonProperty('modified')
  private _modified?: Date;
  @JsonProperty('assignmentId')
  private _assignmentId: number;
  @JsonProperty('userId')
  private _userId: string;
  @JsonProperty('defectReports')
  private _defectReports: number[] = [];

  constructor(assignmentId: number, userId: string) {
    this._assignmentId = assignmentId;
    this._userId = userId;
    this._state = 'IN_REVIEW' as ReviewStateEnum;
  }

  get defectReports(): number[] {
    return this._defectReports;
  }

  set defectReports(value: number[]) {
    this._defectReports = value;
  }

  get id(): number | undefined {
    return this._id;
  }

  set id(value: number | undefined) {
    this._id = value;
  }

  get state(): ReviewStateEnum {
    return this._state;
  }

  set state(value: ReviewStateEnum) {
    this._state = value;
  }

  get comment(): string | null {
    return this._comment;
  }

  set comment(value: string | null) {
    this._comment = value;
  }

  get created(): Date | undefined {
    return this._created;
  }

  set created(value: Date | undefined) {
    this._created = value;
  }

  get modified(): Date | undefined {
    return this._modified;
  }

  set modified(value: Date | undefined) {
    this._modified = value;
  }

  get assignmentId(): number {
    return this._assignmentId;
  }

  set assignmentId(value: number) {
    this._assignmentId = value;
  }

  get userId(): string {
    return this._userId;
  }

  set userId(value: string) {
    this._userId = value;
  }
}
