

































import Component from 'vue-class-component';
import AreaDrawable from '@/models/drawables/AreaDrawable';
import DrawableSvgComponent from '@/components/Editor/drawables/DrawableSvgComponent.vue';
import { Prop, Watch } from 'vue-property-decorator';
import ReviewStateSvgComponent from '@/components/Editor/ReviewStateSvgComponent.vue';
import Point from '@/models/Point';
import { namespace } from 'vuex-class';
import { mapActions, mapState } from 'vuex';
import ReviewAssignment from '@/models/reviews/ReviewAssignment';
import { getCombinedStateOfReviewAssignments } from '@/serializer/ReviewStateHelper';
import ModelElement from '@/models/ModelElement';
import SVGSelectEvent from '@/models/SVGSelectEvent';

const modelEditorReviewStates = namespace('modelEditorReviewStates');

@Component({
  components: { ReviewStateSvgComponent },
  computed: {
    ...mapState('modelEditorReviewStates', ['reviewAssignments']),
  },
  methods: {
    ...mapActions('modelEditorReviewStates', ['getReviewAssignmentsOfNodeByModelElementId']),
  },
})
export default class AreaDrawableSvgComponent extends DrawableSvgComponent {
  @Prop({
    default: false,
    required: false,
  })
  protected showReviewStates!: boolean;

  @modelEditorReviewStates.Action
  protected getReviewAssignmentsOfNodeByModelElementId!: (nodeId: string) => Promise<ReviewAssignment[]>;

  protected reviewAssignments!: ReviewAssignment[];
  protected localReviewAssignments: ReviewAssignment[] = [];

  get areaDrawable(): AreaDrawable {
    return this.modelElement as AreaDrawable;
  }

  mounted(): void {
    if (this.modelElement) {
      this.modelElement.target = this.internalID;
      this.modelElement.translateTarget = this.internalID;
      this.modelElement.resizeTarget = this.internalID;
      this.modelElement.bounds = this.getBounds();
    }
  }

  updated(): void {
    if (this.modelElement) {
      this.modelElement.target = this.internalID;
      this.modelElement.translateTarget = this.internalID;
      this.modelElement.resizeTarget = this.internalID;
    }
  }

  @Watch('modelElement')
  protected handleModelElementChange(newVal: ModelElement, oldVal: ModelElement): void {
    if (newVal !== oldVal) {
      newVal.bounds = this.getBounds();
    }
  }

  @Watch('reviewAssignments') protected handleReviewAssignmentsChange(): void {
    this.loadReviewAssignmentsOfModelElement();
  }

  @Watch('modelElement.startPos')
  protected handleStartPosChange(newVal: Point): void {
    if (this.modelElement) {
      this.modelElement.position.endPos = new Point(newVal.x + this.width, newVal.y + this.height);
    }
  }

  public handleMouseDown(event: MouseEvent): void {
    event.stopPropagation();
    this.$emit('svg-select-down', new SVGSelectEvent(event, true));
  }

  public handleMouseUp(event: MouseEvent): void {
    event.stopPropagation();
    this.$emit('svg-select-up', new SVGSelectEvent(event));
  }

  public handleDoubleClick(event: MouseEvent): void {
    event.stopPropagation();
    this.$emit('svg-select-dbclick', new SVGSelectEvent(event));
  }

  protected loadReviewAssignmentsOfModelElement(): void {
    if (this.modelElement && this.modelElement.id) {
      this.getReviewAssignmentsOfNodeByModelElementId(this.modelElement.id).then((reviewAssignments) => {
        this.localReviewAssignments = reviewAssignments;
      });
    }
  }

  get reviewState(): string {
    return getCombinedStateOfReviewAssignments(this.localReviewAssignments);
  }

  get width(): number {
    if (this.modelElement) {
      return this.modelElement.position.endPos.x - this.modelElement.position.startPos.x;
    }

    return 0;
  }

  get height(): number {
    if (this.modelElement) {
      return this.modelElement.position.endPos.y - this.modelElement.position.startPos.y;
    }

    return 0;
  }
}
