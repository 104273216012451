import MainService from '@/services/MainService';
import { deserialize, serialize } from 'typescript-json-serializer';
import ModelConfig from '@/models/ModelConfig';
import ModelConfigId from '@/models/ModelConfigId';
import { AxiosResponse } from 'axios';

export default class ModelConfigService {
  private static mainURL = '/modelConfig';

  public static getAll(active?: boolean): Promise<ModelConfig[]> {
    return MainService.get(this.mainURL, { params: { active } }).then((response) => {
      return response.data.map((item) => deserialize(item, ModelConfig));
    });
  }

  public static getByID(id: string): Promise<ModelConfig> {
    return MainService.get(this.mainURL + '/' + id + '/').then((response) => {
      return deserialize(response.data, ModelConfig);
    });
  }

  public static getByModelTypeAndVersion(modelType?: string, version?: string): Promise<ModelConfig> {
    return MainService.get(this.mainURL, { params: { modelType, version } }).then((response) => {
      return deserialize(response.data[0], ModelConfig);
    });
  }

  public static save(modelConfig: ModelConfig): Promise<ModelConfig> {
    return MainService.post(this.mainURL, JSON.stringify(serialize(modelConfig))).then((response) => {
      return deserialize(response.data, ModelConfig);
    });
  }

  public static saveByString(modelConfig: string): Promise<ModelConfig> {
    return MainService.post(this.mainURL, modelConfig).then((response) => {
      return deserialize(response.data, ModelConfig);
    });
  }

  public static setActiveState(modelConfig: ModelConfig, active: boolean): Promise<AxiosResponse> {
    return MainService.patch(
      this.mainURL,
      { active },
      {
        params: {
          modelType: modelConfig.modelType,
          version: modelConfig.version,
        },
      }
    );
  }

  public static deleteByTypeAndVersion(modelType: string, version: string): Promise<AxiosResponse> {
    return MainService.delete(this.mainURL, { params: { modelType, version } });
  }

  public static getAllIds(active?: boolean): Promise<ModelConfigId[]> {
    return MainService.get(this.mainURL + '/id', {
      params: { active },
    }).then((response) => {
      return response.data.map((item) => deserialize(item, ModelConfigId));
    });
  }
}
