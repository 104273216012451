import Review from '@/models/reviews/Review';
import Attribute from '@/models/Attribute';
import Point from '@/models/Point';
import Bounds from '@/models/Bounds';
import { JsonProperty, Serializable } from 'typescript-json-serializer';
import TextAnnotation from '@/models/TextAnnotation';
import TextAnnotationDrawable from '@/models/drawables/TextAnnotationDrawable';
import { v4 as uuidv4 } from 'uuid';
import Position from '@/models/Position';
import Marker from '@/models/Marker';

@Serializable()
export default class ModelElement {
  @JsonProperty('id')
  private _id: string = uuidv4();

  @JsonProperty('name')
  protected _name: string;

  @JsonProperty('type')
  protected _type: string;
  @JsonProperty({
    name: 'attributes',
    type: Attribute,
  })
  protected _attributes: Attribute[] = [];
  @JsonProperty('description')
  protected _description: string;

  @JsonProperty('lastUpdated')
  /**
   * Optional properties have to be initialized with undefined otherwise they won't be vue reactive and
   * changes would not be tracked.
   * Backend sends null if not set yet.
   */
  protected _lastUpdated?: Date | null = undefined;
  @JsonProperty('created')
  protected _created?: Date | null = undefined;

  @JsonProperty('position')
  private _position: Position;

  private _groupId?: string = undefined;

  protected _review?: Review = undefined;

  private _target?: string = undefined;
  private _translateTarget?: string = undefined;
  private _resizeTarget?: string = undefined;

  @JsonProperty({
    name: 'annotations',
    type: TextAnnotation,
  })
  protected _annotations: TextAnnotation[] = [];

  @JsonProperty({
    name: 'markers',
    type: Marker,
  })
  private _markers: Marker[] = [];

  private _annotationDrawables: TextAnnotationDrawable[] = [];

  protected _bounds: Bounds = new Bounds(0, 0);

  constructor(type: string, name: string, description: string, startPos: Point, created?: Date) {
    this._type = type;
    this._name = name;
    this._description = description;
    this._created = created;
    this._position = new Position(startPos);
  }

  get type(): string {
    return this._type;
  }

  set type(value: string) {
    this._type = value;
  }

  get attributes(): Attribute[] {
    return this._attributes;
  }

  set attributes(value: Attribute[]) {
    this._attributes = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get description(): string {
    return this._description;
  }

  set description(value: string) {
    this._description = value;
  }

  get lastUpdated(): Date | undefined | null {
    return this._lastUpdated;
  }

  set lastUpdated(value: Date | undefined | null) {
    this._lastUpdated = value;
  }

  get created(): Date | undefined | null {
    return this._created;
  }

  set created(value: Date | undefined | null) {
    this._created = value;
  }

  get position(): Position {
    return this._position;
  }

  set position(value: Position) {
    this._position = value;
  }

  get startPos(): Point {
    return this.position.startPos;
  }

  set startPos(value: Point) {
    this.position.startPos = value;
  }

  get endPos(): Point {
    return this.position.endPos;
  }

  set endPos(value: Point) {
    this.position.endPos = value;
  }

  get bounds(): Bounds {
    return this._bounds;
  }

  set bounds(value: Bounds) {
    this._bounds = value;
  }

  get groupId(): string | undefined {
    return this._groupId;
  }

  set groupId(value: string | undefined) {
    this._groupId = value;
  }

  get review(): Review | undefined {
    return this._review;
  }

  set review(value: Review | undefined) {
    this._review = value;
  }

  get target(): string | undefined {
    return this._target;
  }

  set target(value: string | undefined) {
    this._target = value;
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get translateTarget(): string | undefined {
    return this._translateTarget;
  }

  set translateTarget(value: string | undefined) {
    this._translateTarget = value;
  }

  get annotations(): TextAnnotation[] {
    return this._annotations;
  }

  set annotations(value: TextAnnotation[]) {
    this._annotations = value;
  }

  get markers(): Marker[] {
    return this._markers;
  }

  set markers(value: Marker[]) {
    this._markers = value;
  }

  get annotationDrawables(): TextAnnotationDrawable[] {
    return this._annotationDrawables;
  }

  set annotationDrawables(value: TextAnnotationDrawable[]) {
    this._annotationDrawables = value;
  }

  public generateId(): void {
    this.id = uuidv4();
  }

  get resizeTarget(): string | undefined {
    return this._resizeTarget;
  }

  set resizeTarget(value: string | undefined) {
    this._resizeTarget = value;
  }
}
