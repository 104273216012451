import { JsonProperty, Serializable } from 'typescript-json-serializer';
import { UserRoleEnum } from '@/enums/UserRoleEnum';

@Serializable()
export default class UserInfo {
  @JsonProperty('uid')
  private readonly _uid: string;
  @JsonProperty('fullName')
  private readonly _fullName: string;
  @JsonProperty('roles')
  private readonly _roles: UserRoleEnum[];
  @JsonProperty('firstName')
  private readonly _firstName: string;
  @JsonProperty('lastName')
  private readonly _lastName: string;
  @JsonProperty('email')
  private readonly _email: string;
  @JsonProperty('matriculationNumber')
  private readonly _matriculationNumber: string;

  constructor(
    uid: string,
    fullName: string,
    roles: UserRoleEnum[],
    firstName: string,
    lastName: string,
    email: string,
    matriculationNumber: string
  ) {
    this._uid = uid;
    this._fullName = fullName;
    this._roles = roles;
    this._firstName = firstName;
    this._lastName = lastName;
    this._email = email;
    this._matriculationNumber = matriculationNumber;
  }

  get uid(): string {
    return this._uid;
  }

  get fullName(): string {
    return this._fullName;
  }

  get roles(): UserRoleEnum[] {
    return this._roles;
  }

  get firstName(): string {
    return this._firstName;
  }

  get lastName(): string {
    return this._lastName;
  }

  get email(): string {
    return this._email;
  }

  get matriculationNumber(): string {
    return this._matriculationNumber;
  }
}
