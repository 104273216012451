import NodeType from '@/models/NodeType';
import EdgeType from '@/models/EdgeType';
import { JsonProperty, Serializable } from 'typescript-json-serializer';
import { deserializeLayers, serializeLayers } from '@/serializer/LayersHelper';
import EditorLayer from '@/models/EditorLayer';
import MarkerType from '@/models/MarkerType';

@Serializable()
export default class ModelConfig {
  @JsonProperty('id')
  private readonly _id: string;
  @JsonProperty('modelType')
  private readonly _modelType: string;
  @JsonProperty('version')
  private readonly _version: string;
  @JsonProperty({
    name: 'nodeTypes',
    type: NodeType,
  })
  private _nodeTypes: NodeType[] = [];
  @JsonProperty({
    name: 'edgeTypes',
    type: EdgeType,
  })
  private _edgeTypes: EdgeType[] = [];
  @JsonProperty({
    name: 'markerTypes',
    type: MarkerType,
  })
  private _markerTypes: MarkerType[] = [];

  @JsonProperty('active')
  private _active = true;

  @JsonProperty({
    name: 'layers',
    onSerialize: serializeLayers,
    onDeserialize: deserializeLayers,
  })
  private _layers: EditorLayer[] = [];

  constructor(id: string, modelType: string, markerTypes: MarkerType[], version: string) {
    this._id = id;
    this._markerTypes = markerTypes;
    this._modelType = modelType;
    this._version = version;
  }

  get markerTypes(): MarkerType[] {
    return this._markerTypes;
  }

  set markerTypes(value: MarkerType[]) {
    this._markerTypes = value;
  }

  get nodeTypes(): NodeType[] {
    return this._nodeTypes;
  }

  set nodeTypes(value: NodeType[]) {
    this._nodeTypes = value;
  }

  get edgeTypes(): EdgeType[] {
    return this._edgeTypes;
  }

  set edgeTypes(value: EdgeType[]) {
    this._edgeTypes = value;
  }

  get active(): boolean {
    return this._active;
  }

  set active(value: boolean) {
    this._active = value;
  }

  get id(): string {
    return this._id;
  }

  get modelType(): string {
    return this._modelType;
  }

  get version(): string {
    return this._version;
  }

  public getNodeType(type: string): NodeType | undefined {
    return this.nodeTypes.find((nt: NodeType) => {
      return nt.typeName === type;
    });
  }

  public getEdgeType(type: string): EdgeType | undefined {
    return this.edgeTypes.find((et: EdgeType) => {
      return et.typeName === type;
    });
  }

  public getMarkerType(type: string): MarkerType | undefined {
    return this.markerTypes.find((mt: MarkerType) => {
      return mt.typeName === type;
    });
  }

  // DO NOT USE THIS get layers() function
  // USE getAllLayers() in LayersMixin
  // since this only returns the explicit layers from modelConfig
  // and not transient layers added at runtime e.g. for Markers
  get layers(): EditorLayer[] {
    return this._layers;
  }

  get types(): (NodeType | EdgeType)[] {
    const array: (NodeType | EdgeType)[] = [];
    return array.concat(this.nodeTypes).concat(this.edgeTypes);
  }
}
