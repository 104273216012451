import Vue from 'vue';
import Router from 'vue-router';
import { hasPermission } from '@/auth/AuthService';
import { PossibleAction } from '@/auth/PossibleAction';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: 'login',
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('./views/Login.vue'),
      meta: {
        title: 'Login',
      },
      beforeEnter(to, from, next) {
        const token: string | null = localStorage.getItem('mdre-token');
        const tokenExists: boolean = token !== null && token.length > 0;

        if (tokenExists) {
          next({ name: 'projects' });
        } else {
          next();
        }
      },
    },
    {
      path: '/password/forgot',
      name: 'Forgot Password',
      component: () => import('./views/useradministration/ForgotPassword.vue'),
      meta: {
        title: 'Forgot Password',
      },
    },
    {
      path: '/password/reset',
      name: 'Reset Password',
      component: () => import('./views/useradministration/ResetPassword.vue'),
      meta: {
        title: 'Reset Password',
      },
    },
    {
      path: '/system',
      name: 'system',
      component: () => import('./views/useradministration/SystemAdministration.vue'),
      meta: {
        title: 'Administration',
      },
      beforeEnter(to, from, next) {
        const permission: boolean | null =
          hasPermission(PossibleAction.CAN_NAVIGATE_ADMINISTRATION) || hasPermission(PossibleAction.CAN_NAVIGATE_TAGS);

        if (permission) {
          next();
        } else if (permission === null) {
          // no token
          next({ name: 'login' });
        } else {
          next({ path: from.path });
        }
      },
    },
    {
      path: '/experiment/admin/add',
      name: 'experimentadminadd',
      component: () => import('./views/experiment/AddExperiment.vue'),
      meta: {
        title: 'Add Experiment',
      },
      beforeEnter(to, from, next) {
        const permission: boolean | null = hasPermission(PossibleAction.CAN_NAVIGATE_ADMINISTRATION);

        if (permission) {
          next();
        } else if (permission === null) {
          // no token
          next({ name: 'login' });
        } else {
          next({ path: from.path });
        }
      },
    },
    {
      path: '/experiment/admin/participants/:id',
      name: 'experimentparticipation',
      component: () => import('./views/experiment/AddParticipantsToExperiment.vue'),
      meta: {
        title: 'Add Participants',
      },
      beforeEnter(to, from, next) {
        const permission: boolean | null = hasPermission(PossibleAction.CAN_NAVIGATE_ADMINISTRATION);

        if (permission) {
          next();
        } else if (permission === null) {
          // no token
          next({ name: 'login' });
        } else {
          next({ path: from.path });
        }
      },
    },
    {
      path: '/task/admin/add/:id',
      name: 'taskadd',
      component: () => import('./views/experiment/AddTasksView.vue'),
      meta: {
        title: 'Add Task',
      },
      beforeEnter(to, from, next) {
        const permission: boolean | null = hasPermission(PossibleAction.CAN_NAVIGATE_ADMINISTRATION);
        if (permission) {
          next();
        } else if (permission === null) {
          // no token
          next({ name: 'login' });
        } else {
          next({ path: from.path });
        }
      },
    },
    {
      path: '/task/admin/edit/:experimentId/:taskId',
      name: 'taskedit',
      component: () => import('./views/experiment/EditTaskView.vue'),
      meta: {
        title: 'Edit Task',
      },
      beforeEnter(to, from, next) {
        const permission: boolean | null = hasPermission(PossibleAction.CAN_NAVIGATE_ADMINISTRATION);
        if (permission) {
          next();
        } else if (permission === null) {
          // no token
          next({ name: 'login' });
        } else {
          next({ path: from.path });
        }
      },
    },
    {
      path: '/submission/admin/add/:experimentId/:taskId',
      name: 'submissionadd',
      component: () => import('./views/experiment/AddSubmission.vue'),
      meta: {
        title: 'Add Submission',
      },
      beforeEnter(to, from, next) {
        const permission: boolean | null = hasPermission(PossibleAction.CAN_NAVIGATE_ADMINISTRATION);
        if (permission) {
          next();
        } else if (permission === null) {
          // no token
          next({ name: 'login' });
        } else {
          next({ path: from.path });
        }
      },
    },
    {
      path: '/submission/admin/edit/:experimentId/:taskId/:submissionId',
      name: 'submissionedit',
      component: () => import('./views/experiment/EditSubmission.vue'),
      meta: {
        title: 'Edit Submission',
      },
      beforeEnter(to, from, next) {
        const permission: boolean | null = hasPermission(PossibleAction.CAN_NAVIGATE_ADMINISTRATION);
        if (permission) {
          next();
        } else if (permission === null) {
          // no token
          next({ name: 'login' });
        } else {
          next({ path: from.path });
        }
      },
    },
    {
      path: '/experiment/admin/edit/:id',
      name: 'experimentadminedit',
      component: () => import('./views/experiment/ExperimentAdminDetails.vue'),
      meta: {
        title: 'Edit Experiment',
      },
      beforeEnter(to, from, next) {
        const permission: boolean | null = hasPermission(PossibleAction.CAN_NAVIGATE_ADMINISTRATION);
        if (permission) {
          next();
        } else if (permission === null) {
          // no token
          next({ name: 'login' });
        } else {
          next({ path: from.path });
        }
      },
    },
    {
      path: '/experiment/admin',
      name: 'experimentadmin',
      component: () => import('./views/experiment/ExperimentAdminOverview.vue'),
      meta: {
        title: 'Experiment Admin',
      },
      beforeEnter(to, from, next) {
        const permission: boolean | null = hasPermission(PossibleAction.CAN_NAVIGATE_ADMINISTRATION);

        if (permission) {
          next();
        } else if (permission === null) {
          // no token
          next({ name: 'login' });
        } else {
          next({ path: from.path });
        }
      },
    },
    {
      path: '/experiment/admin/submissions',
      name: 'experimentsubmissions',
      component: () => import('./views/experiment/ExperimentSubmissionOverview.vue'),
      meta: {
        title: 'Experiment Submissions',
      },
      beforeEnter(to, from, next) {
        const permission: boolean | null = hasPermission(PossibleAction.CAN_NAVIGATE_ADMINISTRATION);

        if (permission) {
          next();
        } else if (permission === null) {
          // no token
          next({ name: 'login' });
        } else {
          next({ path: from.path });
        }
      },
    },
    {
      path: '/system/adduser',
      name: 'adduser',
      component: () => import('./views/useradministration/AddUser.vue'),
      meta: {
        title: 'Add User',
      },
      beforeEnter(to, from, next) {
        const permission: boolean | null =
          hasPermission(PossibleAction.CAN_NAVIGATE_ADMINISTRATION) || hasPermission(PossibleAction.CAN_NAVIGATE_TAGS);

        if (permission) {
          next();
        } else if (permission === null) {
          // no token
          next({ name: 'login' });
        } else {
          next({ path: from.path });
        }
      },
    },
    {
      path: '/system/edituser/:id',
      name: 'edituser',
      component: () => import('./views/useradministration/EditUser.vue'),
      meta: {
        title: 'Edit User',
      },
      beforeEnter(to, from, next) {
        const permission: boolean | null =
          hasPermission(PossibleAction.CAN_NAVIGATE_ADMINISTRATION) || hasPermission(PossibleAction.CAN_NAVIGATE_TAGS);

        if (permission) {
          next();
        } else if (permission === null) {
          // no token
          next({ name: 'login' });
        } else {
          next({ path: from.path });
        }
      },
    },
    {
      path: '/selfservice',
      name: 'selfservice',
      component: () => import('./views/useradministration/UserSelfservice.vue'),
      meta: {
        title: 'User Selfservice',
      },
      beforeEnter(to, from, next) {
        const permission: boolean | null = hasPermission(PossibleAction.CAN_NAVIGATE_PROJECTS);
        if (permission) {
          next();
        } else if (permission === null) {
          // no token
          next({ name: 'login' });
        } else {
          next({ path: from.path });
        }
      },
    },
    {
      path: '/projects',
      name: 'projects',
      component: () => import('./views/projects/Projects.vue'),
      meta: {
        title: 'Projects',
      },
      beforeEnter(to, from, next) {
        const permission: boolean | null = hasPermission(PossibleAction.CAN_NAVIGATE_PROJECTS);
        if (permission) {
          next();
        } else if (permission === null) {
          // no token
          next({ name: 'login' });
        } else {
          next({ path: from.path });
        }
      },
    },
    {
      path: '/project/details/:projectId',
      name: 'projectDetails',
      props: true,
      component: () => import('./views/projects/ProjectDetails.vue'),
      meta: {
        title: 'Project Details',
      },
      beforeEnter(to, from, next) {
        const permission: boolean | null = hasPermission(PossibleAction.CAN_NAVIGATE_PROJECT_DETAILS);

        if (permission) {
          next();
        } else if (permission === null) {
          // no token
          next({ name: 'login' });
        } else {
          next({ path: from.path });
        }
      },
    },
    {
      path: '/project/editor/:projectId/:modelId',
      name: 'modelEditor',
      props: true,
      component: () => import('./views/Editor.vue'),
      meta: {
        title: 'Model Editor',
      },
      beforeEnter(to, from, next) {
        const permission: boolean | null = hasPermission(PossibleAction.CAN_NAVIGATE_MODEL_EDITOR);

        if (permission) {
          next();
        } else if (permission === null) {
          // no token
          next({ name: 'login' });
        } else {
          next({ path: from.path });
        }
      },
    },
    {
      path: '/project/assignment/:projectId/:modelId',
      name: 'assignmentCreation',
      props: true,
      component: () => import('./views/assignments/AssignmentCreation.vue'),
      meta: {
        title: 'Assignment Creation',
      },
      beforeEnter(to, from, next) {
        const permission: boolean | null = hasPermission(PossibleAction.CAN_NAVIGATE_ASSIGNMENT_CREATION);

        if (permission) {
          next();
        } else if (permission === null) {
          // no token
          next({ name: 'login' });
        } else {
          next({ path: from.path });
        }
      },
    },
    {
      path: '/project/assignment/details/:projectId/:assignmentId',
      name: 'assignmentDetails',
      props: true,
      component: () => import('./views/assignments/AssignmentDetails.vue'),
      meta: {
        title: 'Assignment Details',
      },
      beforeEnter(to, from, next) {
        const permission: boolean | null = hasPermission(PossibleAction.CAN_NAVIGATE_ASSIGNMENT_DETAILS);

        if (permission) {
          next();
        } else if (permission === null) {
          // no token
          next({ name: 'login' });
        } else {
          next({ path: from.path });
        }
      },
    },
    {
      path: '/project/assignment/review/:projectId/:assignmentId/:reviewId',
      name: 'reviewProcess',
      props: true,
      component: () => import('./views/assignments/ReviewProcess.vue'),
      meta: {
        title: 'Review',
      },
      beforeEnter(to, from, next) {
        const permission: boolean | null = hasPermission(PossibleAction.CAN_NAVIGATE_REVIEW_PROCESS);

        if (permission) {
          next();
        } else if (permission === null) {
          // no token
          next({ name: 'login' });
        } else {
          next({ path: from.path });
        }
      },
    },
    {
      path: '/project/:projectId/model/:modelId/pipelines/executions',
      name: 'pipelineExecutions',
      props: true,
      component: () => import('./views/pipelines/PipelineExecutions.vue'),
      meta: {
        title: 'Pipeline Executions',
      },
      beforeEnter(to, from, next) {
        const permission: boolean | null = hasPermission(PossibleAction.CAN_NAVIGATE_PIPELINES);

        if (permission) {
          next();
        } else if (permission === null) {
          // no token
          next({ name: 'login' });
        } else {
          next({ path: from.path });
        }
      },
    },
    {
      path: '/project/:projectId/model/:modelId/pipelines/executions/:executionId',
      name: 'pipelineExecutionDetails',
      props: true,
      component: () => import('./views/pipelines/PipelineExecutionDetails.vue'),
      meta: {
        title: 'Pipeline Execution Details',
      },
      beforeEnter(to, from, next) {
        const permission: boolean | null = hasPermission(PossibleAction.CAN_NAVIGATE_PIPELINES);

        if (permission) {
          next();
        } else if (permission === null) {
          // no token
          next({ name: 'login' });
        } else {
          next({ path: from.path });
        }
      },
    },
    {
      path: '/configs',
      name: 'configs',
      component: () => import('./views/configs/ModelConfigs.vue'),
      meta: {
        title: 'Model Configs',
      },
      beforeEnter(to, from, next) {
        const permission: boolean | null = hasPermission(PossibleAction.CAN_NAVIGATE_CONFIGS);

        if (permission) {
          next();
        } else if (permission === null) {
          // no token
          next({ name: 'login' });
        } else {
          next({ path: from.path });
        }
      },
    },
    {
      path: '/config/details/:type/:version',
      name: 'configDetails',
      props: true,
      component: () => import('./views/configs/ModelConfigDetails.vue'),
      meta: {
        title: 'Config Details',
      },
      beforeEnter(to, from, next) {
        const permission: boolean | null = hasPermission(PossibleAction.CAN_NAVIGATE_CONFIG_DETAILS);

        if (permission) {
          next();
        } else if (permission === null) {
          // no token
          next({ name: 'login' });
        } else {
          next({ path: from.path });
        }
      },
    },
    {
      path: '/audit',
      name: 'audit',
      props: true,
      component: () => import('./views/AuditLogging.vue'),
      meta: {
        title: 'Audit Logging',
      },
      beforeEnter(to, from, next) {
        const permission: boolean | null = hasPermission(PossibleAction.CAN_NAVIGATE_AUDIT);

        if (permission) {
          next();
        } else if (permission === null) {
          // no token
          next({ name: 'login' });
        } else {
          next({ path: from.path });
        }
      },
    },
    {
      path: '/assignments',
      name: 'assignments',
      component: () => import('./views/assignments/Assignments.vue'),
      meta: {
        title: 'Assignments',
      },
      beforeEnter(to, from, next) {
        const permission: boolean | null = hasPermission(PossibleAction.CAN_NAVIGATE_REVIEWS);

        if (permission) {
          next();
        } else if (permission === null) {
          // no token
          next({ name: 'login' });
        } else {
          next({ path: from.path });
        }
      },
    },
    {
      path: '/project/model/scopes/:projectId/:modelId',
      name: 'modelScopes',
      props: true,
      component: () => import('./views/ModelScopes.vue'),
      meta: {
        title: 'Model Scopes',
      },
      beforeEnter(to, from, next) {
        const permission: boolean | null = hasPermission(PossibleAction.CAN_NAVIGATE_SCOPES);

        if (permission) {
          next();
        } else if (permission === null) {
          // no token
          next({ name: 'login' });
        } else {
          next({ path: from.path });
        }
      },
    },
    {
      path: '*',
      redirect: '/',
    },
  ],
});
