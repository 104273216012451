import Edge from '@/models/Edge';
import Node from '@/models/Node';
import { JsonProperty, Serializable } from 'typescript-json-serializer';
import { drawablePredicate } from '@/serializer/helpers';
import Drawable from '@/models/drawables/Drawable';
import ModelHistoryInput from '@/models/ModelHistoryInput';
import ModelElement from '@/models/ModelElement';
import { PathFindingMechanism } from '@/pathfinding/PathFinding';

@Serializable()
export default class Model {
  @JsonProperty('modelConfig')
  protected readonly _modelConfig: string;
  @JsonProperty('modelConfigVersion')
  protected readonly _modelConfigVersion: string;
  @JsonProperty('modelConfigId')
  private readonly _modelConfigId: string;
  @JsonProperty({
    name: 'nodes',
    type: Node,
  })
  protected _nodes: Node[] = [];
  @JsonProperty({
    name: 'drawables',
    predicate: drawablePredicate,
  })
  protected _drawables: Drawable[] = [];
  @JsonProperty({
    name: 'edges',
    type: Edge,
  })
  protected _edges: Edge[] = [];
  @JsonProperty('projectId')
  protected _projectId: number;
  @JsonProperty('id')
  private _id?: string = undefined;
  @JsonProperty('name')
  private _name: string;
  @JsonProperty('created')
  private _created?: Date;
  @JsonProperty('modified')
  private _modified?: Date;

  @JsonProperty('hasImage')
  private _hasImage = false;

  @JsonProperty('linkedModelIds')
  private _linkedModelIds: (string | null)[] = [];

  @JsonProperty('modelHistoryInputDTO')
  private _modelHistoryInput?: ModelHistoryInput = undefined;

  @JsonProperty('edgeRenderingType')
  private _edgeRenderingType: PathFindingMechanism = PathFindingMechanism.LINEAR;

  constructor(
    name: string,
    modelConfigId: string,
    modelConfig: string,
    modelConfigVersion: string,
    projectId: number,
    id?: string
  ) {
    this._id = id;
    this._name = name;
    this._modelConfigId = modelConfigId;
    this._modelConfig = modelConfig;
    this._modelConfigVersion = modelConfigVersion;
    this._projectId = projectId;
  }

  get nodes(): Node[] {
    return this._nodes;
  }

  set nodes(value: Node[]) {
    this._nodes = value;
  }

  get drawables(): Drawable[] {
    return this._drawables;
  }

  set drawables(value: Drawable[]) {
    this._drawables = value;
  }

  get edges(): Edge[] {
    return this._edges;
  }

  set edges(value: Edge[]) {
    this._edges = value;
  }

  get projectId(): number {
    return this._projectId;
  }

  get id(): string | undefined {
    return this._id;
  }

  set id(value: string | undefined) {
    this._id = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get created(): Date | undefined {
    return this._created;
  }

  set created(value: Date | undefined) {
    this._created = value;
  }

  get modified(): Date | undefined {
    return this._modified;
  }

  set modified(value: Date | undefined) {
    this._modified = value;
  }

  get modelConfig(): string {
    return this._modelConfig;
  }

  get modelConfigVersion(): string {
    return this._modelConfigVersion;
  }

  get modelConfigId(): string {
    return this._modelConfigId;
  }

  get hasImage(): boolean {
    return this._hasImage;
  }

  set hasImage(value: boolean) {
    this._hasImage = value;
  }

  get modelHistoryInput(): ModelHistoryInput | undefined {
    return this._modelHistoryInput;
  }

  set modelHistoryInput(value: ModelHistoryInput | undefined) {
    this._modelHistoryInput = value;
  }

  get itemCount(): number {
    return this.nodes.length + this.edges.length + this.drawables.length;
  }

  get linkedModelIds(): (string | null)[] {
    return this._linkedModelIds;
  }

  set linkedModelIds(value: (string | null)[]) {
    this._linkedModelIds = value;
  }

  get modelElements(): ModelElement[] {
    return Array.prototype.concat(this.nodes, this.edges, this.drawables);
  }

  get edgeRenderingType(): PathFindingMechanism {
    return this._edgeRenderingType;
  }

  set edgeRenderingType(value: PathFindingMechanism) {
    this._edgeRenderingType = value;
  }
}
