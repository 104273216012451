import { ModelHistoryCategoryEnum } from '@/enums/ModelHistoryCategoryEnum';
import { ModelActionEnum } from '@/enums/ModelActionEnum';
import { PriorityEnum } from '@/enums/PriorityEnum';
import { ReviewMethodEnum } from '@/enums/ReviewMethodEnum';
import { AssignmentStateEnum } from '@/enums/AssignmentStateEnum';
import { ReviewStateEnum } from '@/enums/ReviewStateEnum';
import { PipelineStateEnum } from '@/models/pipelines/PipelineStateEnum';
import { TaskTypeEnum } from '@/models/experiment/TaskTypeEnum';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
const serializeEnum = (enumValue: any | null): string => {
  return enumValue;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
const deserializePriority = (priority: any): PriorityEnum => {
  return PriorityEnum[priority as keyof typeof PriorityEnum];
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
const deserializeReviewMethod = (reviewMethod: any): ReviewMethodEnum => {
  return ReviewMethodEnum[reviewMethod as keyof typeof ReviewMethodEnum];
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
const deserializeAssignmentState = (state: any): AssignmentStateEnum => {
  return AssignmentStateEnum[state as keyof typeof AssignmentStateEnum];
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
const deserializeReviewState = (state: any): ReviewStateEnum => {
  return ReviewStateEnum[state as keyof typeof ReviewStateEnum];
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
const deserializeModelHistoryCategory = (category: any): ModelHistoryCategoryEnum => {
  return ModelHistoryCategoryEnum[category as keyof typeof ModelHistoryCategoryEnum];
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
const deserializeModelHistoryAction = (action: any): ModelActionEnum => {
  return ModelActionEnum[action as keyof typeof ModelActionEnum];
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
const deserializePipelineState = (state: any): PipelineStateEnum => {
  return PipelineStateEnum[state as keyof typeof PipelineStateEnum];
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
const deserializeTaskType = (type: any): TaskTypeEnum => {
  return TaskTypeEnum[type as keyof typeof TaskTypeEnum];
};
export {
  serializeEnum,
  deserializePriority,
  deserializeReviewMethod,
  deserializeAssignmentState,
  deserializeReviewState,
  deserializeModelHistoryCategory,
  deserializeModelHistoryAction,
  deserializePipelineState,
  deserializeTaskType,
};
