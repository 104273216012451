export default class SVGSelectEvent {
  /**
   *
   */
  private _originalEvent: MouseEvent;
  private _drag: boolean;

  constructor(originalEvent: MouseEvent, drag = false) {
    this._originalEvent = originalEvent;
    this._drag = drag;
  }

  get originalEvent(): MouseEvent {
    return this._originalEvent;
  }

  get drag(): boolean {
    return this._drag;
  }
}
