import ModelElement from '@/models/ModelElement';
import Bounds from '@/models/Bounds';
import Point from '@/models/Point';
import { Serializable } from 'typescript-json-serializer';

@Serializable()
export default class Drawable extends ModelElement {
  public readonly vueComponent: string = 'no-component';

  constructor(type: string, name: string, description: string, startPos: Point, bounds?: Bounds, created?: Date) {
    super(type, name, description, startPos, created);
    if (bounds) {
      this._bounds = bounds;
    }
  }
}
