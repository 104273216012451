import { JsonProperty, Serializable } from 'typescript-json-serializer';

@Serializable()
export default class UserTagged {
  @JsonProperty('id')
  private readonly _id: number;
  @JsonProperty('uid')
  private readonly _uid: string;
  @JsonProperty('tagIds')
  private _tagIds: number[];

  private _fullName = '';
  private _tagsChanged = false;

  constructor(id: number, uid: string, tagIds: number[]) {
    this._id = id;
    this._uid = uid;
    this._tagIds = tagIds;
  }

  get id(): number {
    return this._id;
  }

  get uid(): string {
    return this._uid;
  }

  get tagIds(): number[] {
    return this._tagIds;
  }

  set tagIds(value: number[]) {
    this._tagIds = value;
  }

  get fullName(): string {
    return this._fullName;
  }

  set fullName(value: string) {
    this._fullName = value;
  }

  get tagsChanged(): boolean {
    return this._tagsChanged;
  }

  set tagsChanged(value: boolean) {
    this._tagsChanged = value;
  }
}
