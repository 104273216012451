import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import ModelElementMarker from '@/models/ModelElementMarker';
import { ModelElementMarkerTypeEnum } from '@/enums/ModelElementMarkerTypeEnum';
import { deserialize, serialize } from 'typescript-json-serializer';

@Module({
  namespaced: true,
})
export default class ModelEditorMarkers extends VuexModule {
  private markers: ModelElementMarker[] = [];
  private selectedMarkerType: ModelElementMarkerTypeEnum | null = null;
  private reviewAssignmentId: number | null = null;

  @Mutation
  public addMarkerMutation(marker: ModelElementMarker): void {
    this.markers.push(marker);
  }

  @Action({ commit: 'addMarkerMutation' })
  public addMarker(marker: ModelElementMarker): ModelElementMarker {
    if (typeof Storage !== 'undefined' && this.reviewAssignmentId) {
      window.localStorage.setItem(
        'mdre_review_' + this.reviewAssignmentId,
        JSON.stringify([marker].concat(this.markers).map((mark) => serialize(mark)))
      );
    }
    return marker;
  }

  @Mutation
  public setSelectedMarkerTypeMutation(markerType: ModelElementMarkerTypeEnum): void {
    this.selectedMarkerType = markerType;
  }

  @Action({ commit: 'setSelectedMarkerTypeMutation' })
  public setSelectedMarkerType(markerType: ModelElementMarkerTypeEnum): ModelElementMarkerTypeEnum {
    return markerType;
  }

  @Mutation
  public setMarkersMutation(markers: ModelElementMarker[]): void {
    this.markers = markers;
  }

  @Action({ commit: 'setMarkersMutation' })
  public setMarkers(markers: ModelElementMarker[]): ModelElementMarker[] {
    if (typeof Storage !== 'undefined' && this.reviewAssignmentId) {
      window.localStorage.setItem(
        'mdre_review_' + this.reviewAssignmentId,
        JSON.stringify(markers.map((mark) => serialize(mark)))
      );
    }
    return markers;
  }

  @Mutation
  public setReviewAssignmentIdMutation(reviewAssignmentId: number): void {
    this.reviewAssignmentId = reviewAssignmentId;
  }

  @Action({ commit: 'setReviewAssignmentIdMutation' })
  public setReviewAssignmentId(reviewAssignmentId: number): number {
    return reviewAssignmentId;
  }

  @Action({ commit: 'setMarkersMutation' })
  public clearMarkers(): ModelElementMarker[] {
    return [];
  }

  @Action({ commit: 'setMarkersMutation' })
  public loadMarkers(): ModelElementMarker[] {
    if (typeof Storage !== 'undefined' && this.reviewAssignmentId) {
      const item = window.localStorage.getItem('mdre_review_' + this.reviewAssignmentId);
      if (item) {
        return JSON.parse(item).map((item) => deserialize(item, ModelElementMarker));
      }
    }
    return [];
  }
}
