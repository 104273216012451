import { JsonProperty, Serializable } from 'typescript-json-serializer';

@Serializable()
export default class Attribute {
  @JsonProperty('name')
  private readonly _name: string;
  @JsonProperty('value')
  private _value: string;

  constructor(name: string, value: string) {
    this._name = name;
    this._value = value;
  }

  get name(): string {
    return this._name;
  }

  get value(): string {
    return this._value;
  }

  set value(value: string) {
    this._value = value;
  }
}
