/**
 * Inject general layer
 * @param layers
 */
import EditorLayer from '@/models/EditorLayer';

const deserializeLayers = (layers: string[]): EditorLayer[] => {
  if (layers.includes('General')) {
    return layers.map((layerName) => new EditorLayer(layerName));
  } else {
    const generalLayers = [new EditorLayer('General')];
    return Array.from(generalLayers).concat(layers.map((layerName) => new EditorLayer(layerName)));
  }
};

const serializeLayers = (layers: EditorLayer[]): string[] => {
  return layers.map((layer) => layer.name);
};

export { deserializeLayers, serializeLayers };
