import MainService from '@/services/MainService';
import { deserialize, serialize } from 'typescript-json-serializer';
import Project from '@/models/Project';
import ProjectChanges from '@/models/ProjectChanges';
import { AxiosResponse } from 'axios';

export default class ProjectService {
  private static mainURL = '/project';

  public static getAll(): Promise<Project[]> {
    return MainService.get(this.mainURL).then((response) => {
      return response.data.map((item) => deserialize(item, Project));
    });
  }

  public static getById(id: number): Promise<Project> {
    return MainService.get(this.mainURL + '/' + id + '/').then((response) => {
      return deserialize(response.data, Project);
    });
  }

  public static getByName(name: string): Promise<Project> {
    return MainService.get(this.mainURL, { params: { name } }).then((response) => {
      return deserialize(response.data[0], Project);
    });
  }

  public static save(project: Project): Promise<Project> {
    return MainService.post(this.mainURL, JSON.stringify(serialize(project))).then((response) => {
      return deserialize(response.data, Project);
    });
  }

  public static delete(id: number): Promise<AxiosResponse> {
    return MainService.delete(this.mainURL + '/' + id + '/');
  }

  public static update(project: Project): Promise<Project> {
    return MainService.put(this.mainURL + '/' + project.id + '/', JSON.stringify(serialize(project))).then(
      (response) => {
        return deserialize(response.data, Project);
      }
    );
  }

  public static updateParticipants(projectId: number, projectChanges: ProjectChanges): Promise<AxiosResponse> {
    return MainService.patch(this.mainURL + '/' + projectId + '/', JSON.stringify(serialize(projectChanges)));
  }
}
