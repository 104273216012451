import { JsonProperty, Serializable } from 'typescript-json-serializer';
import { ModelHistoryCategoryEnum } from '@/enums/ModelHistoryCategoryEnum';

@Serializable()
export default class ModelHistoryInput {
  @JsonProperty('comment')
  private readonly _comment: string;
  @JsonProperty('category')
  private readonly _category: ModelHistoryCategoryEnum;

  constructor(comment: string, category: ModelHistoryCategoryEnum) {
    this._comment = comment;
    this._category = category;
  }

  get comment(): string {
    return this._comment;
  }

  get category(): ModelHistoryCategoryEnum {
    return this._category;
  }
}
