import MainService from '@/services/MainService';
import { deserialize, serialize } from 'typescript-json-serializer';
import ModelScope from '@/models/ModelScope';
import { AxiosResponse } from 'axios';

export default class ModelScopeService {
  private static mainURL = '/modelscope';

  public static getAllByModelId(modelId: string): Promise<ModelScope[]> {
    return MainService.get(this.mainURL + '/model/' + modelId).then((response) => {
      return response.data.map((item) => deserialize(item, ModelScope));
    });
  }

  public static getById(id: string): Promise<ModelScope> {
    return MainService.get(this.mainURL + '/' + id + '/').then((response) => {
      return deserialize(response.data, ModelScope);
    });
  }

  public static save(modelScope: ModelScope): Promise<ModelScope> {
    return MainService.post(this.mainURL, JSON.stringify(serialize(modelScope))).then((response) => {
      return deserialize(response.data, ModelScope);
    });
  }

  public static delete(id: string): Promise<AxiosResponse> {
    return MainService.delete(this.mainURL + '/' + id + '/');
  }
}
