export enum PossibleAction {
  CAN_NAVIGATE_ADMINISTRATION = 'CAN_NAVIGATE_ADMINISTRATION',
  CAN_NAVIGATE_TAGS = 'CAN_NAVIGATE_TAGS',
  CAN_NAVIGATE_PROJECTS = 'CAN_NAVIGATE_PROJECTS',
  CAN_NAVIGATE_PROJECT_DETAILS = 'CAN_NAVIGATE_PROJECT_DETAILS',
  CAN_NAVIGATE_MODEL_EDITOR = 'CAN_NAVIGATE_MODEL_EDITOR',
  CAN_NAVIGATE_SCOPES = 'CAN_NAVIGATE_SCOPES',
  CAN_NAVIGATE_ASSIGNMENT_CREATION = 'CAN_NAVIGATE_ASSIGNMENT_CREATION',
  CAN_NAVIGATE_ASSIGNMENT_DETAILS = 'CAN_NAVIGATE_ASSIGNMENT_DETAILS',
  CAN_NAVIGATE_REVIEW_PROCESS = 'CAN_NAVIGATE_REVIEW_PROCESS',
  CAN_NAVIGATE_CONFIGS = 'CAN_NAVIGATE_CONFIGS',
  CAN_NAVIGATE_CONFIG_DETAILS = 'CAN_NAVIGATE_CONFIG_DETAILS',
  CAN_NAVIGATE_REVIEWS = 'CAN_NAVIGATE_REVIEWS',
  CAN_NAVIGATE_AUDIT = 'CAN_NAVIGATE_AUDIT',
  CAN_NAVIGATE_PIPELINES = 'CAN_NAVIGATE_PIPELINES',

  CAN_GET_AUDIT = 'CAN_GET_AUDIT',

  CAN_GET_CONFIG_IDS = 'CAN_GET_CONFIG_IDS',
  CAN_GET_CONFIG = 'CAN_GET_CONFIG',
  CAN_SAVE_CONFIG = 'CAN_SAVE_CONFIG',
  CAN_DELETE_CONFIG = 'CAN_DELETE_CONFIG',
  CAN_UPDATE_CONFIG = 'CAN_UPDATE_CONFIG',

  CAN_GET_MODEL = 'CAN_GET_MODEL',
  CAN_SAVE_MODEL = 'CAN_SAVE_MODEL',
  CAN_UPDATE_MODEL = 'CAN_UPDATE_MODEL',
  CAN_DELETE_MODEL = 'CAN_DELETE_MODEL',

  CAN_ANALYSE_MODEL = 'CAN_ANALYSE_MODEL',

  CAN_IMPORT_MODEL = 'CAN_IMPORT_MODEL',
  CAN_EXPORT_MODEL = 'CAN_EXPORT_MODEL',

  CAN_GET_IMAGE = 'CAN_GET_IMAGE',
  CAN_SAVE_IMAGE = 'CAN_SAVE_IMAGE',
  CAN_DELETE_IMAGE = 'CAN_DELETE_IMAGE',

  CAN_GET_SCOPE = 'CAN_GET_SCOPE',
  CAN_SAVE_SCOPE = 'CAN_SAVE_SCOPE',
  CAN_DELETE_SCOPE = 'CAN_DELETE_SCOPE',

  CAN_GET_PROJECT = 'CAN_GET_PROJECT',
  CAN_SAVE_PROJECT = 'CAN_SAVE_PROJECT',
  CAN_UPDATE_PROJECT = 'CAN_UPDATE_PROJECT',
  CAN_DELETE_PROJECT = 'CAN_DELETE_PROJECT',

  CAN_GET_ASSIGNMENT_FOR_EDITOR = 'CAN_GET_ASSIGNMENT_FOR_EDITOR',
  CAN_GET_ASSIGNMENT = 'CAN_GET_ASSIGNMENT',
  CAN_SAVE_ASSIGNMENT = 'CAN_SAVE_ASSIGNMENT',
  CAN_UPDATE_ASSIGNMENT = 'CAN_UPDATE_ASSIGNMENT',
  CAN_DELETE_ASSIGNMENT = 'CAN_DELETE_ASSIGNMENT',
  CAN_EXPORT_ASSIGNMENT = 'CAN_EXPORT_ASSIGNMENT',

  CAN_GET_REVIEW = 'CAN_GET_REVIEW',
  CAN_SAVE_REVIEW = 'CAN_SAVE_REVIEW',
  CAN_UPDATE_REVIEW = 'CAN_UPDATE_REVIEW',
  CAN_DELETE_REVIEW = 'CAN_DELETE_REVIEW',

  CAN_GET_TAG = 'CAN_GET_TAG',
  CAN_SAVE_TAG = 'CAN_SAVE_TAG',
  CAN_DELETE_TAG = 'CAN_DELETE_TAG',

  CAN_GET_USER_INFO = 'CAN_GET_USER_INFO',

  CAN_GET_USER_TAGS = 'CAN_GET_USER_TAGS',
  CAN_SAVE_USER_TAGS = 'CAN_SAVE_USER_TAGS',
  CAN_UPDATE_USER_TAGS = 'CAN_UPDATE_USER_TAGS',
  CAN_DELETE_USER_TAGS = 'CAN_DELETE_USER_TAGS',

  CAN_RUN_PIPELINE = 'CAN_RUN_PIPELINE',
}
