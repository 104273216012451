import PlacementRule from '@/models/PlacementRule';
import ModelElement from '@/models/ModelElement';
import Point from '@/models/Point';
import Intersects from 'intersects';
import { JsonProperty, Serializable } from 'typescript-json-serializer';

@Serializable()
export default class InsidePlacementRule extends PlacementRule {
  @JsonProperty('inside')
  private readonly _inside: boolean;
  @JsonProperty('strict')
  private readonly _strictInside: boolean;

  constructor(type: string, inside = true, strictInside = false) {
    super(type);
    this._inside = inside;
    this._strictInside = strictInside;
  }

  get inside(): boolean {
    return this._inside;
  }

  get strictInside(): boolean {
    return this._strictInside;
  }

  public placementAllowed(element: ModelElement, parent: ModelElement, point: Point): boolean {
    if (element.type !== this.type) {
      return true;
    }

    if (this._inside) {
      if (this._strictInside) {
        // the whole element has to be in parent
        if (
          point.x >= parent.startPos.x &&
          point.x <= parent.startPos.x + parent.bounds.width &&
          point.x + element.bounds.width <= parent.startPos.x + parent.bounds.width &&
          point.y >= parent.startPos.y &&
          point.y <= parent.startPos.y + parent.bounds.height &&
          point.y + element.bounds.height <= parent.startPos.y + parent.bounds.height
        ) {
          return true;
        }
      } else {
        // only intersect with parent
        return Intersects.boxBox(
          point.x,
          point.y,
          element.bounds.width,
          element.bounds.height,
          parent.startPos.x,
          parent.startPos.y,
          parent.bounds.width,
          parent.bounds.height
        );
      }
    } else {
      return !Intersects.boxBox(
        point.x,
        point.y,
        element.bounds.width,
        element.bounds.height,
        parent.startPos.x,
        parent.startPos.y,
        parent.bounds.width,
        parent.bounds.height
      );
    }

    return false;
  }
}
