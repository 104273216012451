import ReviewAssignment from '@/models/reviews/ReviewAssignment';
import { AssignmentStateEnum } from '@/enums/AssignmentStateEnum';

const getCombinedStateOfReviewAssignments = (reviewAssignments: ReviewAssignment[]): string => {
  if (reviewAssignments.length === 0) {
    return 'UNDEFINED';
  }

  let allFinished = true;
  let atLeastOneInProgess = false;
  let atLeastOneUnclear = false;
  let atLeastOneNotOk = false;

  reviewAssignments.forEach((reviewAssignment) => {
    if (reviewAssignment.state === AssignmentStateEnum.IN_PROCESS) {
      allFinished = false;

      if (!atLeastOneInProgess) {
        atLeastOneInProgess = true;
      }
    }

    if (reviewAssignment.state === AssignmentStateEnum.UNCLEAR) {
      if (!atLeastOneUnclear) {
        atLeastOneUnclear = true;
      }
    }

    if (reviewAssignment.state === AssignmentStateEnum.NOT_OK) {
      if (!atLeastOneNotOk) {
        atLeastOneNotOk = true;
      }
    }
  });

  if (atLeastOneInProgess) {
    return 'IN_PROCESS';
  } else if (atLeastOneUnclear) {
    return 'UNCLEAR';
  } else if (atLeastOneNotOk) {
    return 'NOT_OK';
  } else if (allFinished) {
    return 'OK';
  }

  return 'UNDEFINED';
};

export { getCombinedStateOfReviewAssignments };
