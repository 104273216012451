import ModelElement from '@/models/ModelElement';
import { JsonProperty, Serializable } from 'typescript-json-serializer';

@Serializable()
export default class Node extends ModelElement {
  @JsonProperty('parentId')
  private _parentId?: string | null = undefined;

  private _parentNode?: Node = undefined;

  get parentNode(): Node | undefined {
    return this._parentNode;
  }

  set parentNode(value: Node | undefined) {
    this._parentNode = value;
    if (value !== undefined) {
      this._parentId = value.id;
    }
  }

  get parentId(): string | null | undefined {
    return this._parentId;
  }

  set parentId(value: string | null | undefined) {
    this._parentId = value;
  }
}
