import { JsonProperty, Serializable } from 'typescript-json-serializer';

@Serializable()
export default class AttributeType {
  @JsonProperty('variableName')
  private _variableName: string;
  /**
   * Optional properties have to be initialized with undefined otherwise they won't be vue reactive and
   * changes would not be tracked.
   */
  @JsonProperty('displayName')
  private _displayName: string;
  @JsonProperty('type')
  private _type: string;
  @JsonProperty('nullable')
  private _nullable: boolean;

  constructor(variableName: string, type: string, displayName: string, nullable: boolean) {
    this._variableName = variableName;
    this._type = type;
    this._displayName = displayName;
    this._nullable = nullable;
  }

  get variableName(): string {
    return this._variableName;
  }

  get displayName(): string {
    return this._displayName;
  }

  get type(): string {
    return this._type;
  }

  get nullable(): boolean {
    return this._nullable;
  }
}
