import { ModelElementMarkerTypeEnum } from '@/enums/ModelElementMarkerTypeEnum';
import Point from '@/models/Point';
import { JsonProperty, Serializable } from 'typescript-json-serializer';

@Serializable()
export default class ModelElementMarker {
  @JsonProperty('type')
  private readonly _type: ModelElementMarkerTypeEnum;
  @JsonProperty('position')
  private _position: Point;

  constructor(type: ModelElementMarkerTypeEnum, position: Point) {
    this._type = type;
    this._position = position;
  }

  get type(): ModelElementMarkerTypeEnum {
    return this._type;
  }

  get position(): Point {
    return this._position;
  }

  set position(value: Point) {
    this._position = value;
  }
}
