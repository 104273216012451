import MainService from '@/services/MainService';
import { deserialize, serialize } from 'typescript-json-serializer';
import Review from '@/models/reviews/Review';
import ReviewChanges from '@/models/reviews/ReviewChanges';
import { AxiosResponse } from 'axios';

export default class ReviewService {
  private static mainURL = '/review';

  public static getAll(): Promise<Review[]> {
    return MainService.get(this.mainURL).then((response) => {
      return response.data.map((item) => deserialize(item, Review));
    });
  }

  public static getAllByUserID(userId: string): Promise<Review[]> {
    return MainService.get(this.mainURL, { params: { userId } }).then((response) => {
      return response.data.map((item) => deserialize(item, Review));
    });
  }

  public static getAllByAssignmentId(assignmentId: number): Promise<Review[]> {
    return MainService.get(this.mainURL, { params: { assignmentId } }).then((response) => {
      return response.data.map((item) => deserialize(item, Review));
    });
  }

  public static getById(id: number): Promise<Review> {
    return MainService.get(this.mainURL + '/' + id + '/').then((response) => {
      return deserialize(response.data, Review);
    });
  }

  public static save(review: Review): Promise<Review> {
    return MainService.post(this.mainURL, JSON.stringify(serialize(review))).then((response) => {
      return deserialize(response.data, Review);
    });
  }

  public static update(reviewId: number, reviewChanges: ReviewChanges): Promise<AxiosResponse> {
    return MainService.patch(this.mainURL + '/' + reviewId + '/', JSON.stringify(serialize(reviewChanges)));
  }

  public static delete(reviewId: number): Promise<AxiosResponse> {
    return MainService.delete(this.mainURL + '/' + reviewId + '/');
  }
}
