




import BaseSvgComponent from '@/components/Editor/BaseSvgComponent.vue';
import Component from 'vue-class-component';
import Bounds from '@/models/Bounds';
import { Prop } from 'vue-property-decorator';
import ModelConfig from '@/models/ModelConfig';

@Component({})
export default class DrawableSvgComponent extends BaseSvgComponent {
  /*
  @Prop({
    type: DrawableSvgComponent,
    required: true
  })
  protected drawableType!: DrawableSvgComponent;
   */
  @Prop({
    required: true,
  })
  protected currentConfig!: ModelConfig;

  mounted(): void {
    if (this.modelElement) {
      this.modelElement.bounds = this.getBounds();
    }
  }

  protected getBounds(): Bounds {
    if (this.modelElement && this.modelElement.position.endPos) {
      return new Bounds(
        Math.abs(this.modelElement.position.endPos.x - this.modelElement.position.startPos.x),
        Math.abs(this.modelElement.position.endPos.y - this.modelElement.position.startPos.y)
      );
    }
    return new Bounds(0, 0);
  }
}
