import Tag from '@/models/users/Tag';
import { UserRoleEnum } from '@/enums/UserRoleEnum';

export default class CurrentUser {
  private readonly _uid: string;
  private _name = '';
  private _tags: Tag[] = [];
  private _roles: UserRoleEnum[] = [];

  constructor(uid: string) {
    this._uid = uid;
  }

  get uid(): string {
    return this._uid;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get tags(): Tag[] {
    return this._tags;
  }

  set tags(value: Tag[]) {
    this._tags = value;
  }

  get roles(): UserRoleEnum[] {
    return this._roles;
  }

  set roles(value: UserRoleEnum[]) {
    this._roles = value;
  }
}
