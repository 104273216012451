import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import ModelElement from '@/models/ModelElement';

@Module({
  namespaced: true,
})
/**
 * Contains necessary data to draw connections between nodes
 */
export default class DrawEdges extends VuexModule {
  private connectModeEnabled = false;
  private sourceNode?: ModelElement = undefined;
  private targetNode?: ModelElement = undefined;
  private connectionType?: string = undefined;

  @Mutation
  public mutateConnectModeEnabled(value: boolean): void {
    this.connectModeEnabled = value;
  }

  @Mutation
  public mutateSourceNode(node: ModelElement | undefined): void {
    this.sourceNode = node;
  }

  @Mutation
  public mutateTargetNode(node: ModelElement | undefined): void {
    this.targetNode = node;
  }

  @Mutation
  public mutateConnectionType(type: string | undefined): void {
    this.connectionType = type;
  }

  @Action({ commit: 'mutateConnectModeEnabled' })
  public setConnectModeEnabled(value: boolean): boolean {
    return value;
  }

  @Action({ commit: 'mutateSourceNode' })
  public setSourceNode(node: ModelElement | undefined): ModelElement | undefined {
    return node;
  }

  @Action({ commit: 'mutateTargetNode' })
  public setTargetNode(node: ModelElement | undefined): ModelElement | undefined {
    return node;
  }

  @Action({ commit: 'mutateConnectionType' })
  public setConnectionType(type: string | undefined): string | undefined {
    return type;
  }
}
