import { Features } from '@/enums/Features';

export default class Feature {
  /**
   * Defines which features are available including their value for non-production builds.
   */
  public static features = {
    // 'true'/'false' needs to be string, otherwise replacement by nginx does not work.
    [Features.DEBUGBAR]: process.env.NODE_ENV === 'production' ? 'NGINX_REPLACE_FEATURE_DEBUGBAR' : 'true',
    [Features.BLUEPRINT]: process.env.NODE_ENV === 'production' ? 'NGINX_REPLACE_FEATURE_BLUEPRINT' : 'true',
    [Features.MODEL_DIFFERENCE]:
      process.env.NODE_ENV === 'production' ? 'NGINX_REPLACE_FEATURE_MODEL_DIFFERENCE' : 'true',
  };

  public static isEnabled(featureName: string): boolean {
    if (this.features[featureName]) {
      return this.features[featureName] === 'true';
    }
    // defaults to false
    return false;
  }

  public static allFeatures(): string {
    let string = '';

    const keys = Object.keys(this.features);

    keys.forEach((key, index) => {
      string += key + '=' + (this.features[key] === 'true' ? 'enabled' : 'disabled');
      if (index < keys.length - 1) {
        string += ', ';
      }
    });

    return string;
  }
}
