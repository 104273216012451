import { JsonProperty, Serializable } from 'typescript-json-serializer';

@Serializable()
export default class Tag {
  @JsonProperty('id')
  private readonly _id?: number;
  @JsonProperty('name')
  private readonly _name: string;

  constructor(name: string, id?: number) {
    this._id = id;
    this._name = name;
  }

  get id(): number | undefined {
    return this._id;
  }

  get name(): string {
    return this._name;
  }
}
