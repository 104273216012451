import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';
import EditorLayer from '@/models/EditorLayer';
import { Action, State } from 'vuex-class';
import ModelConfig from '@/models/ModelConfig';

@Component({})
export class LayersMixins extends Vue {
  protected currentConfig!: ModelConfig;

  @Action('setEditorLayers', { namespace: 'modelEditor' })
  protected setEditorLayers!: (editorLayers: EditorLayer[]) => void;

  @State('editorLayers', { namespace: 'modelEditor' })
  protected markerLayers!: EditorLayer[];

  protected getAllLayers(): EditorLayer[] {
    if (this.markerLayers.length == 0) {
      this.makeMarkerTypesToLayers();
    }
    return this.markerLayers;
  }

  public getLayer(base64Name: string): EditorLayer | undefined {
    return this.markerLayers.find((layer: EditorLayer) => {
      return layer.base64Name === base64Name;
    });
  }

  protected clearEditorLayers(): void {
    this.setEditorLayers([]);
  }

  private makeMarkerTypesToLayers(): EditorLayer[] {
    if (this.markerLayers.length == 0) {
      this.setEditorLayers(
        this.currentConfig.layers.concat(
          this.currentConfig.markerTypes.map((markerType) => new EditorLayer(markerType.typeName))
        )
      );
    }
    return this.markerLayers;
  }
}
