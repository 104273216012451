import { JsonProperty, Serializable } from 'typescript-json-serializer';

@Serializable()
export default class ModelConfigId {
  @JsonProperty('id')
  private readonly _id: string;
  @JsonProperty('modelType')
  private readonly _modelType: string;
  @JsonProperty('version')
  private readonly _version: string;

  constructor(id: string, modelType: string, version: string) {
    this._id = id;
    this._modelType = modelType;
    this._version = version;
  }

  get id(): string {
    return this._id;
  }

  get modelType(): string {
    return this._modelType;
  }

  get version(): string {
    return this._version;
  }
}
