import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import UserInfo from '@/models/users/UserInfo';
import Tag from '@/models/users/Tag';

@Module({
  namespaced: true,
})
export default class UserManagement extends VuexModule {
  public availableUsers: UserInfo[] = [];
  public availableTags: Tag[] = [];

  @Mutation
  public mutateAvailableUsers(value: UserInfo[]): void {
    this.availableUsers = value;
  }

  @Action({ commit: 'mutateAvailableUsers' })
  public setAvailableUsers(value: UserInfo[]): UserInfo[] {
    return value;
  }

  @Mutation
  public mutateAvailableTags(value: Tag[]): void {
    this.availableTags = value;
  }

  @Action({ commit: 'mutateAvailableTags' })
  public setAvailableTags(value: Tag[]): Tag[] {
    return value;
  }
}
