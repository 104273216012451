import Point from '@/models/Point';
import PathFindingLinear from '@/pathfinding/PathFindingLinear';
import PathFindingTaxicabGeometry from '@/pathfinding/PathFindingTaxicabGeometry';
import Node from '@/models/Node';

export enum PathFindingMechanism {
  TAXICAB = 'taxicab',
  LINEAR = 'linear',
}

const PathFindingMechanismLabels = {
  [PathFindingMechanism.LINEAR]: 'Default',
  [PathFindingMechanism.TAXICAB]: 'Taxicab Geometry',
};

export interface PathFindingToupleInfo {
  startPoint: Point;
  endPoint: Point;
  startNode: Node;
  endNode: Node;
}

export interface PathFindingMechanismOption {
  text: string;
  value: string;
}

export default class PathFinding {
  public static getSegmentPoints(toupleInfo: PathFindingToupleInfo, mechanism: PathFindingMechanism) {
    if (!mechanism) {
      mechanism = PathFindingMechanism.LINEAR;
    }
    switch (mechanism) {
      case PathFindingMechanism.LINEAR:
        return PathFindingLinear.getSegmentPoints(toupleInfo);
      case PathFindingMechanism.TAXICAB:
        return PathFindingTaxicabGeometry.getSegmentPoints(toupleInfo);
    }
  }

  public static getPathFindingMechanismOptions(): PathFindingMechanismOption[] {
    const keys = Object.keys(PathFindingMechanismLabels);
    return keys.map((key) => {
      return {
        text: PathFindingMechanismLabels[key],
        value: key,
      };
    });
  }
}
