import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';
import Feature from '@/features/Feature';
import { Features } from '@/enums/Features';

@Component({})
export class FeatureMixin extends Vue {
  /**
   * Injects Features enum into component to allow usage in HTML-Template
   * @protected
   */
  protected readonly Features = Features;

  /**
   * Returns a boolean value if feature is on/off
   * @param featureName Name of the feature to be checked
   * @returns Boolean true if feature is on, false otherwise
   * @protected
   */
  protected isFeatureEnabled(featureName) {
    return Feature.isEnabled(featureName);
  }

  /**
   * Returns all features with its current values as comma-separated string
   */
  get featureString(): string {
    return Feature.allFeatures();
  }
}
