import ModelElementType from '@/models/ModelElementType';
import AllowedConnection from '@/models/AllowedConnection';
import { JsonProperty, Serializable } from 'typescript-json-serializer';
import AttributeType from '@/models/AttributeType';

@Serializable()
export default class EdgeType extends ModelElementType {
  @JsonProperty({
    name: 'connects',
    type: AllowedConnection,
  })
  private readonly _connects: AllowedConnection[] = [];

  @JsonProperty({
    name: 'bidirectional',
  })
  private _bidirectional = true;

  constructor(
    typeName: string,
    attributeTypes: AttributeType[],
    display: string,
    connects: AllowedConnection[],
    bidirectional?: boolean
  ) {
    super(typeName, attributeTypes, display);
    this._connects = connects;
    if (bidirectional) {
      this._bidirectional = bidirectional;
    }
  }

  get connects(): AllowedConnection[] {
    return this._connects;
  }

  get bidirectional(): boolean {
    return this._bidirectional;
  }

  set bidirectional(value: boolean) {
    this._bidirectional = value;
  }

  public isConnectionAllowed(
    fromType: string,
    toType: string
  ): { allowed: boolean; connection: AllowedConnection | null } {
    let allowedConnection: AllowedConnection | null = null;
    let connectionAllowed = false;

    if (this._connects) {
      if (this._connects.length > 0) {
        const foundConnection = this._connects.find((ac: AllowedConnection) => {
          return ac.from === fromType && ac.to === toType;
        });

        if (foundConnection) {
          // connection is allowed.
          allowedConnection = foundConnection;
          connectionAllowed = true;
        }
      } else {
        // if nothing is defined than connection is allowed by default.
        connectionAllowed = true;
      }
    }

    return {
      allowed: connectionAllowed,
      connection: allowedConnection,
    };
  }
}
