enum ModelActionEnum {
  MODEL_CREATE,
  MODEL_UPDATE,
  MODEL_DELETE,
  BACKGROUND_SAVE,
  BACKGROUND_DELETE,
}

const modelActionEnumLabels = {
  [ModelActionEnum.MODEL_CREATE]: 'Model created',
  [ModelActionEnum.MODEL_UPDATE]: 'Model updated',
  [ModelActionEnum.MODEL_DELETE]: 'Model deleted',
  [ModelActionEnum.BACKGROUND_SAVE]: 'Model background saved',
  [ModelActionEnum.BACKGROUND_DELETE]: 'Model background deleted',
};

export { ModelActionEnum, modelActionEnumLabels };
