import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';
import Config from '@/config';

@Component({})
export class Toasts extends Vue {
  protected showToast(title: string, msg: string, variant: string): void {
    if (variant !== 'info' || (variant === 'info' && Config.SHOW_INFO_TOASTS)) {
      this.$bvToast.toast(msg, {
        title,
        variant,
        solid: true,
        autoHideDelay: variant === 'danger' ? 5000 : 2500,
      });
    }
  }
}
