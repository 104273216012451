import { JsonProperty, Serializable } from 'typescript-json-serializer';
import MarkerState from '@/models/MarkerState';

@Serializable()
export default class MarkerType {
  @JsonProperty('typeName')
  private readonly _typeName: string;
  @JsonProperty('markerStates')
  private _markerStates: MarkerState[] = [];
  @JsonProperty('layer')
  private _layer: string | null = 'General';

  constructor(typeName: string, markerStates: MarkerState[], layer: string | null) {
    this._typeName = typeName;
    this._markerStates = markerStates;
    this._layer = layer;
  }

  get typeName(): string {
    return this._typeName;
  }

  get markerStates(): MarkerState[] {
    return this._markerStates;
  }

  set markerStates(value: MarkerState[]) {
    this._markerStates = value;
  }

  get layer(): string | null {
    return this.typeName;
  }

  set layer(value: string | null) {
    this._layer = value;
  }
}
