import { JsonProperty, Serializable } from 'typescript-json-serializer';

@Serializable()
export default class Project {
  @JsonProperty('id')
  protected readonly _id?: number;
  @JsonProperty('name')
  protected _name: string;
  @JsonProperty('owners')
  private _owners: string[] = [];
  @JsonProperty('members')
  private _members: string[] = [];
  @JsonProperty('created')
  protected readonly _created?: Date;
  @JsonProperty('modified')
  private _modified?: Date;

  constructor(name: string, id?: number, created?: Date, modified?: Date) {
    this._name = name;
    this._id = id;
    this._created = created;
    this._modified = modified;
  }

  get id(): number | undefined {
    return this._id;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get owners(): string[] {
    return this._owners;
  }

  set owners(value: string[]) {
    this._owners = value;
  }

  get members(): string[] {
    return this._members;
  }

  set members(value: string[]) {
    this._members = value;
  }

  get created(): Date | undefined {
    return this._created;
  }

  get modified(): Date | undefined {
    return this._modified;
  }

  set modified(value: Date | undefined) {
    this._modified = value;
  }
}
