import CurrentUser from '@/models/users/CurrentUser';
import { authMatrixAdmin, authMatrixConfigurator, authMatrixDesigner, authMatrixReviewer } from './RoleBasedAuth';
import { PossibleAction } from '@/auth/PossibleAction';
import Project from '@/models/Project';
import { authMatrixProjectMember, authMatrixProjectOwner } from '@/auth/MembershipBasedAuth';
import ReviewAssignment from '@/models/reviews/ReviewAssignment';
import Review from '@/models/reviews/Review';
import StoredReviewAssignment from '@/models/reviews/StoredReviewAssignment';
import { ReviewStateEnum } from '@/enums/ReviewStateEnum';
import { UserRoleEnum } from '@/enums/UserRoleEnum';
import jwtDecode from 'jwt-decode';

function getCurrentUser(): CurrentUser | null {
  const token = localStorage.getItem('mdre-token');
  if (token === null || token.length === 0) {
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  const deToken: any = jwtDecode(token);
  const user = new CurrentUser(deToken.sub);
  user.name = deToken.fn;
  let roles: string = deToken.rol[0];
  roles = roles.substring(1, roles.length - 1);
  roles.split(', ').forEach((role) => user.roles.push(UserRoleEnum[role]));

  return user;
}

function hasAssignmentMembershipPermission(
  currentUserID: string,
  aForOwnerCheck: ReviewAssignment | StoredReviewAssignment,
  action: PossibleAction
): boolean {
  let hasPermission = false;

  if (aForOwnerCheck.owners !== undefined) {
    if (
      aForOwnerCheck.owners.indexOf(currentUserID) > -1 ||
      (action !== PossibleAction.CAN_UPDATE_ASSIGNMENT && action !== PossibleAction.CAN_DELETE_ASSIGNMENT)
    ) {
      hasPermission = true;
    }
  }

  return hasPermission;
}

function hasProjectMembershipPermission(
  currentUserID: string,
  pForMembershipCheck: Project,
  action: PossibleAction
): boolean {
  let hasPermission = false;

  if (pForMembershipCheck.owners != null && pForMembershipCheck.owners.indexOf(currentUserID) > -1) {
    hasPermission = authMatrixProjectOwner[action];
  }
  if (
    !hasPermission &&
    pForMembershipCheck.members != null &&
    pForMembershipCheck.members.indexOf(currentUserID) > -1
  ) {
    hasPermission = authMatrixProjectMember[action];
  }

  return hasPermission;
}

function hasPermission(
  action: PossibleAction,
  pForMembershipCheck?: Project,
  aForOwnerCheck?: ReviewAssignment | StoredReviewAssignment,
  rForOwnerCheck?: Review
): boolean | null {
  if (action === null) {
    return false;
  }

  const currentUser: CurrentUser | null = getCurrentUser();

  if (currentUser == null || currentUser.roles === null) {
    return null;
  }

  if (currentUser.uid === null || currentUser.uid.length === 0 || currentUser.roles.length === 0) {
    return false;
  }

  let hasPermission = false;

  if (currentUser.roles.indexOf(UserRoleEnum.ROLE_ADMIN) > -1) {
    hasPermission = authMatrixAdmin[action];
  } else {
    if (!hasPermission && currentUser.roles.indexOf(UserRoleEnum.ROLE_CONFIGURATOR) > -1) {
      hasPermission = authMatrixConfigurator[action];
    }
    if (!hasPermission && currentUser.roles.indexOf(UserRoleEnum.ROLE_REVIEWER) > -1) {
      hasPermission = authMatrixReviewer[action];
    }
    if (!hasPermission && currentUser.roles.indexOf(UserRoleEnum.ROLE_DESIGNER) > -1) {
      hasPermission = authMatrixDesigner[action];
    }

    if (pForMembershipCheck) {
      const membershipPermission = hasProjectMembershipPermission(currentUser.uid, pForMembershipCheck, action);
      hasPermission = hasPermission && (membershipPermission ? true : membershipPermission === null);
    }

    if (pForMembershipCheck && aForOwnerCheck) {
      hasPermission =
        hasPermission &&
        (pForMembershipCheck.owners.indexOf(currentUser.uid) > -1 ||
          pForMembershipCheck.members.indexOf(currentUser.uid) > -1);
      const membershipPermission = hasAssignmentMembershipPermission(currentUser.uid, aForOwnerCheck, action);
      hasPermission = hasPermission && (membershipPermission ? true : membershipPermission === null);
    }

    if (rForOwnerCheck) {
      if (action === PossibleAction.CAN_UPDATE_REVIEW) {
        hasPermission = rForOwnerCheck.userId === currentUser.uid;
      } else if (action === PossibleAction.CAN_DELETE_REVIEW) {
        hasPermission = hasPermission && ReviewStateEnum[rForOwnerCheck.state] !== ReviewStateEnum.IN_REVIEW;

        if (pForMembershipCheck) {
          hasPermission =
            hasPermission &&
            (pForMembershipCheck.owners.indexOf(currentUser.uid) > -1 ||
              pForMembershipCheck.members.indexOf(currentUser.uid) > -1);
        } else {
          hasPermission = false;
        }

        if (aForOwnerCheck) {
          hasPermission = hasPermission && aForOwnerCheck.owners.indexOf(currentUser.uid) > -1;
        }
      }
    }
  }

  return hasPermission;
}

function getCurrentUserID(): string {
  const user = getCurrentUser();
  if (user) {
    return user.uid;
  }
  return '';
}

function getCurrentUserRoles(): UserRoleEnum[] {
  const user = getCurrentUser();
  if (user) {
    return user.roles;
  }
  return [];
}

export { hasPermission, getCurrentUserID, getCurrentUserRoles };
