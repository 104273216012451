import axios from 'axios';
import Config from '@/config';
import Main from '@/main';

const inst = axios.create({
  baseURL: Config.BACKEND_HOST,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  },
});

inst.interceptors.request.use(function (config) {
  config.headers.Authorization = localStorage.getItem('mdre-token');
  return config;
});

inst.interceptors.response.use(
  function (response) {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      if (Main.$router.currentRoute.name !== 'login') {
        Main.$emit('logout');
      }
    }
    return Promise.reject(error);
  }
);

export default inst;
