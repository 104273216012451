import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { ModelDetailsDTO } from '@/model-difference/interfaces/ModelDetailsDTO';

export interface AddModelToCompareListDTO {
  modelId: string;
  modelName: string;
  configId: string;
  projectName: string;
}

@Module({
  namespaced: true,
})
/**
 * Contains necessary data to conduct model difference analysis
 */
export default class ModelDifference extends VuexModule {
  /**
   * Contains the ids of models to compare their differences
   * @private
   */
  private _compareList: Record<string, ModelDetailsDTO> = {};

  /**
   * Holds maximum length of compareList
   * @private
   */
  private _capacity = 2;

  get compareList(): Record<string, ModelDetailsDTO> {
    return this._compareList;
  }

  get capacity(): number {
    return this._capacity;
  }

  get capacityLeft(): boolean {
    return Object.keys(this._compareList).length < this._capacity;
  }

  @Mutation
  public setCompareList(compareList: Record<string, ModelDetailsDTO>): void {
    this._compareList = compareList;
  }

  @Mutation
  public addModelToCompareList(addModelToCompareListDTO: AddModelToCompareListDTO): void {
    this._compareList = {
      ...this._compareList,
      [addModelToCompareListDTO.modelId]: {
        name: addModelToCompareListDTO.modelName,
        configId: addModelToCompareListDTO.configId,
        projectName: addModelToCompareListDTO.projectName,
      },
    };
  }

  @Mutation
  public removeModelFromCompareList(modelId: string): void {
    const compareList: Record<string, ModelDetailsDTO> = {};

    Object.keys(this._compareList).forEach((id) => {
      if (id !== modelId) {
        compareList[id] = this._compareList[id];
      }
    });

    this._compareList = compareList;
  }
}
