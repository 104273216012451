import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import Model from '@/models/Model';
import ModelSummary from '@/models/ModelSummary';

@Module({
  namespaced: true,
})
export default class Project extends VuexModule {
  private currentProject?: Project = undefined;
  private currentProjectId?: number = undefined;

  private models: Model[] = [];

  /**
   * Changes the current project to the given one
   * @param project new current project
   */
  @Mutation
  public mutateCurrentProject(project: Project | undefined): void {
    this.currentProject = project;
  }

  /**
   * Calls mutation to change current project to the given one. Action does not change payload.
   * @param project new current project
   */
  @Action({ commit: 'mutateCurrentProject' })
  public setCurrentProject(project: Project | undefined): Project | undefined {
    return project;
  }

  /**
   * Changes the current project id to the given one
   * @param projectId new current project id
   */
  @Mutation
  public mutateCurrentProjectId(projectId: number | undefined): void {
    this.currentProjectId = projectId;
  }

  /**
   * Calls mutation to change current project id to the given one. Action does not change payload.
   * @param projectId new current project id
   */
  @Action({ commit: 'mutateCurrentProjectId' })
  public setCurrentProjectId(projectId: number | undefined): number | undefined {
    return projectId;
  }

  /**
   * Changes the models array
   * @param models Array of models
   */
  @Mutation
  public mutateModels(models: Model[]): void {
    this.models = models;
  }

  /**
   * Adds new model to array
   * @param model Model to add
   */
  @Mutation
  public addModel(model: Model): void {
    this.models.push(model);
  }

  /**
   * Removes model from array
   * @param model Model to remove
   */
  @Mutation
  public removeModel(model: Model): void {
    this.models.splice(this.models.indexOf(model), 1);
  }

  /**
   * Calls mutation to change models to the given ones. Action does not change payload.
   * @param models Array of models
   */
  @Action({ commit: 'mutateModels' })
  public setModels(models: Model[]): Model[] {
    return models;
  }

  @Action({})
  public getModelSummaryOfId(modelId: string): ModelSummary {
    if (typeof this.modelSummaries[modelId] === 'undefined') {
      return new ModelSummary(modelId, '');
    }

    return this.modelSummaries[modelId];
  }

  get modelSummaries(): { [key: string]: ModelSummary } {
    const modelSummary = {};

    this.models.forEach((model) => {
      if (model.id) {
        modelSummary[model.id] = new ModelSummary(model.id, model.name);
      }
    });

    return modelSummary;
  }
}
