import { JsonProperty, Serializable } from 'typescript-json-serializer';
import { v4 as uuidv4 } from 'uuid';
import Position from '@/models/Position';
import ModelConfig from '@/models/ModelConfig';

@Serializable()
export default class Marker {
  @JsonProperty('id')
  private _id: string = uuidv4();

  @JsonProperty('name')
  private _name: string;

  @JsonProperty('type')
  private _type: string;

  @JsonProperty('description')
  private _description: string;

  @JsonProperty('markerState')
  private _markerState?: string = undefined;

  @JsonProperty('lastUpdated')
  private _lastUpdated?: Date | null = undefined;
  @JsonProperty('created')
  private _created?: Date | null = undefined;

  @JsonProperty('position')
  private _position: Position;

  constructor(
    id: string,
    name: string,
    type: string,
    description: string,
    markerState: string,
    lastUpdated: Date | null,
    position: Position,
    created?: Date
  ) {
    this._id = id;
    this._name = name;
    this._type = type;
    this._description = description;
    this._markerState = markerState;
    this._lastUpdated = lastUpdated;
    this._created = created;
    this._position = position;
  }

  get markerState(): string | undefined {
    return this._markerState;
  }

  set markerState(value: string | undefined) {
    this._markerState = value;
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get type(): string {
    return this._type;
  }

  set type(value: string) {
    this._type = value;
  }

  get description(): string {
    return this._description;
  }

  set description(value: string) {
    this._description = value;
  }

  get lastUpdated(): Date | undefined | null {
    return this._lastUpdated;
  }

  set lastUpdated(value: Date | undefined | null) {
    this._lastUpdated = value;
  }

  get created(): Date | undefined | null {
    return this._created;
  }

  set created(value: Date | undefined | null) {
    this._created = value;
  }

  get position(): Position {
    return this._position;
  }

  set position(value: Position) {
    this._position = value;
  }
}
