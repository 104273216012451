var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (this.textAnnotation)?_c('g',{on:{"mousedown":_vm.handleMouseDown,"mouseup":_vm.handleMouseUp,"click":_vm.handleClick}},[_c('path',{style:({
      transform:
        'translate(' +
        this.textAnnotation.textAnnotation.position.startPos.x +
        'px, ' +
        this.textAnnotation.textAnnotation.position.startPos.y +
        'px)',
    }),attrs:{"d":'M0,0 L' + (_vm.width - 25) + ',0 L' + _vm.width + ',25 L' + _vm.width + ',' + _vm.height + ' L0,' + _vm.height + ' Z',"fill":"white","fill-opacity":"1","stroke-opacity":"1","stroke-width":"1","stroke":"black"}}),_c('path',{style:({
      transform:
        'translate(' +
        this.textAnnotation.textAnnotation.position.startPos.x +
        'px, ' +
        this.textAnnotation.textAnnotation.position.startPos.y +
        'px)',
    }),attrs:{"d":'M0,0 L' +
      (_vm.width - 25) +
      ',0 L' +
      (_vm.width - 25) +
      ',25 L' +
      _vm.width +
      ',25 L' +
      _vm.width +
      ',' +
      _vm.height +
      ' L0,' +
      _vm.height +
      ' Z',"fill":"white","fill-opacity":"1","stroke-opacity":"1","stroke-width":"1","stroke":"black"}}),_c('g',{style:(_vm.innerstyle)},[_c('foreignObject',{ref:"textWrapper",attrs:{"height":_vm.height - 25,"width":_vm.width - 5},domProps:{"innerHTML":_vm._s(_vm.text)}})],1),_c('rect',{ref:"rect",style:({
      width: _vm.width + 'px',
      height: _vm.height + 'px',
      transform:
        'translate(' +
        this.textAnnotation.textAnnotation.position.startPos.x +
        'px, ' +
        this.textAnnotation.textAnnotation.position.startPos.y +
        'px)',
    }),attrs:{"fill-opacity":"0","stroke-opacity":"0","stroke-width":"1","data-element-id":_vm.internalID}}),_c('path',{attrs:{"d":_vm.pathToElement,"stroke-width":"1","stroke":"black"}})]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }