
















import { Prop, Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import Point from '../../models/Point';
import Bounds from '@/models/Bounds';

@Component({})
export default class ReviewStateSvgComponent extends Vue {
  @Prop({
    default: 'IN_PROCESS',
    required: true,
  })
  protected reviewState!: string;
  @Prop({
    default: false,
    required: false,
  })
  protected isArrow!: boolean;

  @Prop({
    required: true,
    default: () => new Bounds(0, 0),
  })
  protected bounds!: Bounds;

  get reviewPosition(): Point {
    if (this.isArrow) {
      return new Point(this.bounds.width / 2, this.bounds.height / 2);
    }

    return new Point(this.bounds.width / 2, 15);
  }

  get tooltipTitle(): string {
    switch (this.reviewState) {
      case 'IN_PROCESS':
        return 'Review is in progress';
      case 'OK':
        return 'Reviews are done';
      case 'UNCLEAR':
        return 'Something is unclear';
      case 'NOT_OK':
        return 'Something is not ok';
      default:
        return 'Unknown state';
    }
  }

  get reviewInProgess(): boolean {
    return this.reviewState === 'IN_PROCESS';
  }

  get reviewAllOk(): boolean {
    return this.reviewState === 'OK';
  }

  get reviewUnclear(): boolean {
    return this.reviewState === 'UNCLEAR';
  }

  get reviewWarning(): boolean {
    return this.reviewState === 'NOT_OK';
  }
}
