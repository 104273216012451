enum ModelHistoryCategoryEnum {
  UPDATED_NODE,
  CREATED_NODE,
  REMOVED_NODE,
  UPDATED_EDGE,
  CREATED_EDGE,
  REMOVED_EDGE,
  CREATED_NODES_AND_EDGES,
  REMOVED_NODES_AND_EDGES,
  UPDATED_NODES_AND_EDGES,
  OTHER,
}

const modelHistoryCategoryEnumLabels = {
  [ModelHistoryCategoryEnum.UPDATED_NODE]: 'Node(s) updated',
  [ModelHistoryCategoryEnum.CREATED_NODE]: 'Node(s) added',
  [ModelHistoryCategoryEnum.REMOVED_NODE]: 'Node(s) removed',
  [ModelHistoryCategoryEnum.UPDATED_EDGE]: 'Edge(s) updated',
  [ModelHistoryCategoryEnum.CREATED_EDGE]: 'Edge(s) added',
  [ModelHistoryCategoryEnum.REMOVED_EDGE]: 'Edge(s) removed',
  [ModelHistoryCategoryEnum.CREATED_NODES_AND_EDGES]: 'Node(s) and Edge(s) added',
  [ModelHistoryCategoryEnum.REMOVED_NODES_AND_EDGES]: 'Node(s) and Edge(s) removed',
  [ModelHistoryCategoryEnum.UPDATED_NODES_AND_EDGES]: 'Node(s) and Edge(s) updated',
  [ModelHistoryCategoryEnum.OTHER]: 'Other',
};

export { ModelHistoryCategoryEnum, modelHistoryCategoryEnumLabels };
