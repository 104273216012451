import ModelElement from '@/models/ModelElement';

export default class SelectedModelElement {
  constructor(modelElement: ModelElement, target: string) {
    this._modelElement = modelElement;
    this._target = target;
  }

  private _modelElement: ModelElement;

  get modelElement(): ModelElement {
    return this._modelElement;
  }

  private _target: string;

  get target(): string {
    return this._target;
  }
}
