import { PathFindingToupleInfo } from '@/pathfinding/PathFinding';

export enum SIDE {
  TOP,
  LEFT,
  BOTTOM,
  RIGHT,
}

export default class PathFindingTaxicabGeometry {
  /**
   * Returns edge segment points including endPoint
   * @param toupleInfo
   */
  public static getSegmentPoints(toupleInfo: PathFindingToupleInfo) {
    const { startPoint, endPoint, startNode, endNode } = toupleInfo;

    const pdx = endPoint.x - startPoint.x;
    const pdy = endPoint.y - startPoint.y;

    const startRelativePointX = startPoint.x - startNode.startPos.x;
    const startRelativePointY = startPoint.y - startNode.startPos.y;

    const endRelativePointX = endPoint.x - endNode.startPos.x;
    const endRelativePointY = endPoint.y - endNode.startPos.y;

    const getDirection = (x, y, node) => {
      const offset = 20;
      let direction = SIDE.RIGHT;
      if (y <= offset) {
        // TOP
        direction = SIDE.TOP;
      } else if (y >= node.bounds.height - offset) {
        // BOTTOM
        direction = SIDE.BOTTOM;
      } else if (x <= node.bounds.width / 2) {
        // LEFT
        direction = SIDE.LEFT;
      } else {
        // RIGHT
      }

      return direction;
    };

    const startDirection = getDirection(startRelativePointX, startRelativePointY, startNode);
    const endDirection = getDirection(endRelativePointX, endRelativePointY, endNode);

    const pts: number[][] = [];

    const isHorizontalLShape =
      (startDirection === SIDE.LEFT || startDirection === SIDE.RIGHT) &&
      (endDirection === SIDE.TOP || endDirection === SIDE.BOTTOM);
    const isHorizontalZShape =
      (startDirection === SIDE.LEFT || startDirection === SIDE.RIGHT) &&
      (endDirection === SIDE.LEFT || endDirection === SIDE.RIGHT);
    const isHorizontalUShape =
      (startDirection === SIDE.TOP && endDirection === SIDE.TOP) ||
      (startDirection === SIDE.BOTTOM && startDirection === SIDE.BOTTOM);
    const isVerticalLShape =
      (startDirection === SIDE.TOP || startDirection === SIDE.BOTTOM) &&
      (endDirection === SIDE.LEFT || endDirection === SIDE.RIGHT);
    const isVerticalZShape =
      (startDirection === SIDE.TOP || startDirection === SIDE.BOTTOM) &&
      (endDirection === SIDE.TOP || endDirection === SIDE.BOTTOM);

    if (isHorizontalLShape) {
      pts.push([pdx, 0]);
    } else if (isHorizontalZShape) {
      const x = pdx / 2;

      pts.push([x, 0], [x, pdy]);
    } /* else if (isHorizontalUShape) {
      return [
        [0, d],
        [pdy, d]
      ]
    }*/ else if (
      isVerticalLShape
    ) {
      pts.push([0, pdy]);
    } else if (isVerticalZShape) {
      const y = pdy / 2;

      pts.push([0, y], [endPoint.x - startPoint.x, y]);
    }

    // endpoint
    pts.push([pdx, pdy]);

    return pts;
  }
}
