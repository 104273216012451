import { JsonProperty, Serializable } from 'typescript-json-serializer';

@Serializable()
export default class ModelScope {
  @JsonProperty('id')
  private _id?: string;
  @JsonProperty('description')
  private _description?: string;
  @JsonProperty('nodeIdsToReview')
  private _nodeIds: string[] = [];
  @JsonProperty('edgeIdsToReview')
  private _edgeIds: string[] = [];
  @JsonProperty('drawableIdsToReview')
  private _drawableIds: string[] = [];
  @JsonProperty('modelId')
  private _modelId: string;
  @JsonProperty('created')
  private readonly _created?: Date;
  @JsonProperty('modified')
  private _modified?: Date;

  private _isComplete = true;

  constructor(modelId: string) {
    this._modelId = modelId;
  }

  get id(): string | undefined {
    return this._id;
  }

  set id(value: string | undefined) {
    this._id = value;
  }

  get description(): string | undefined {
    return this._description;
  }

  set description(value: string | undefined) {
    this._description = value;
  }

  get nodeIds(): string[] {
    return this._nodeIds;
  }

  set nodeIds(value: string[]) {
    this._nodeIds = value;
  }

  get edgeIds(): string[] {
    return this._edgeIds;
  }

  set edgeIds(value: string[]) {
    this._edgeIds = value;
  }

  get drawableIds(): string[] {
    return this._drawableIds;
  }

  set drawableIds(value: string[]) {
    this._drawableIds = value;
  }

  get modelId(): string {
    return this._modelId;
  }

  set modelId(value: string) {
    this._modelId = value;
  }

  get created(): Date | undefined {
    return this._created;
  }

  get modified(): Date | undefined {
    return this._modified;
  }

  get isComplete(): boolean {
    return this._isComplete;
  }

  set isComplete(value: boolean) {
    this._isComplete = value;
  }

  get elementCount(): number {
    return this.nodeIds.length + this.edgeIds.length + this.drawableIds.length;
  }
}
