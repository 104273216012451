import Vue from 'vue';
import Vuex, { ModuleTree } from 'vuex';
import modelEditor from './store/modules/ModelEditor';
import drawEdges from './store/modules/DrawEdges';
import userManagement from './store/modules/UserManagement';
import modelEditorReviewStates from './store/modules/ModelEditorReviewStates';
import project from './store/modules/Project';
import modelElementMarkers from './store/modules/ModelEditorMarkers';
import { Features } from '@/enums/Features';
import Feature from '@/features/Feature';
import ModelDifference from '@/model-difference/store-module/ModelDifference';

Vue.use(Vuex);

let modules: ModuleTree<any> = {
  /**
   * Module used by Editor view
   */
  modelEditor,
  drawEdges,
  userManagement,
  modelEditorReviewStates,
  project,
  modelElementMarkers,
};

/** -- FEATURE DEPENDING MODULES -- */
if (Feature.isEnabled(Features.MODEL_DIFFERENCE)) {
  modules = {
    ModelDifference,
    ...modules,
  };
}

export default new Vuex.Store({
  modules: modules,
});
