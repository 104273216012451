import { JsonProperty, Serializable } from 'typescript-json-serializer';
import Position from '@/models/Position';

@Serializable()
export default class TextAnnotation {
  @JsonProperty('id')
  private _id: string;

  @JsonProperty('description')
  private _description: string;

  @JsonProperty('position')
  private _position: Position;

  constructor(id: string, description: string, position: Position) {
    this._id = id;
    this._description = description;
    this._position = position;
  }

  get description(): string {
    return this._description;
  }

  set description(value: string) {
    this._description = value;
  }

  get position(): Position {
    return this._position;
  }

  set position(value: Position) {
    this._position = value;
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }
}
