









































































import Component from 'vue-class-component';
import DrawableSvgComponent from '@/components/Editor/drawables/DrawableSvgComponent.vue';
import TextAnnotationDrawable from '@/models/drawables/TextAnnotationDrawable';
import { Vue, Watch } from 'vue-property-decorator';
import Point from '@/models/Point';
import Position from '@/models/Position';
import Bounds from '@/models/Bounds';
import Edge from '@/models/Edge';
import { htmlEntities } from '@/serializer/helpers';
import SVGSelectEvent from '@/models/SVGSelectEvent';

@Component({})
export default class TextAnnotationDrawableSvgComponent extends DrawableSvgComponent {
  public $refs!: Vue['$refs'] & {
    svg_group: SVGElement;
    textWrapper: SVGForeignObjectElement;
  };

  get textAnnotation(): TextAnnotationDrawable {
    return this.modelElement as TextAnnotationDrawable;
  }

  mounted(): void {
    if (this.modelElement) {
      this.modelElement.target = this.internalID;
      this.modelElement.translateTarget = this.internalID;
      this.modelElement.bounds = this.getBounds();
      this.modelElement.position = this.textAnnotation.textAnnotation.position;
    }
  }

  updated(): void {
    if (this.modelElement) {
      // this.modelElement.target = this.internalID;
      // this.modelElement.translateTarget = this.internalID;
    }
  }

  @Watch('modelElement.position.startPos')
  protected handleStartPosChanged(newVal: Point): void {
    if (this.modelElement) {
      this.textAnnotation.textAnnotation.position.endPos = new Point(
        newVal.x + this.modelElement.bounds.width,
        newVal.y + this.modelElement.bounds.height
      );
    }
    this.textAnnotation.textAnnotation.position.startPos = newVal;
  }

  @Watch('modelElement.bounds')
  protected handleBoundsChanged(newVal: Bounds): void {
    this.textAnnotation.textAnnotation.position.endPos = new Point(
      this.textAnnotation.textAnnotation.position.startPos.x + newVal.width,
      this.textAnnotation.textAnnotation.position.startPos.y + newVal.height
    );
  }

  @Watch('modelElement.position.endPos')
  protected handleEndPosChanged(newVal: Point): void {
    Vue.set(this.textAnnotation.textAnnotation.position, 'endPos', newVal);
  }

  protected getBounds(): Bounds {
    return new Bounds(this.width, this.height);
  }

  public handleMouseDown(event: MouseEvent): void {
    event.stopPropagation();
    this.$emit('svg-select-down', new SVGSelectEvent(event, true));
  }

  public handleMouseUp(event: MouseEvent): void {
    event.stopPropagation();
    this.$emit('svg-select-up', new SVGSelectEvent(event));
  }

  get pathToElement(): string {
    const nearestPoints = this.nearestPoints;
    let offsetX = 5;
    let offsetY = 5;
    let path = '';

    if (nearestPoints) {
      const deltaX = nearestPoints.startPos.x - nearestPoints.endPos.x;
      const deltaY = nearestPoints.startPos.y - nearestPoints.endPos.y;

      if (deltaX > 0) {
        offsetX *= -1;
      }

      if (deltaY > 0) {
        offsetY *= -1;
      }

      path = 'M' + (nearestPoints.startPos.x + offsetX) + ',' + (nearestPoints.startPos.y + offsetY);
      path += ' L' + (nearestPoints.endPos.x - offsetX) + ',' + (nearestPoints.endPos.y - offsetY);
    }

    return path;
  }

  get nearestPoints(): Position | undefined {
    if (!this.modelElement) {
      return undefined;
    }

    const startPoints = [
      // top left
      this.textAnnotation.textAnnotation.position.startPos,
      // top right
      new Point(
        this.textAnnotation.textAnnotation.position.startPos.x + this.width,
        this.textAnnotation.textAnnotation.position.startPos.y
      ),
      // bottom right
      new Point(
        this.textAnnotation.textAnnotation.position.startPos.x + this.width,
        this.textAnnotation.textAnnotation.position.startPos.y + this.height
      ),
      // bottom left
      new Point(
        this.textAnnotation.textAnnotation.position.startPos.x,
        this.textAnnotation.textAnnotation.position.startPos.y + this.height
      ),
    ];

    let endPoints: Point[] = [];

    if (this.textAnnotation.belongsTo instanceof Edge) {
      endPoints = [
        new Point(
          (this.textAnnotation.belongsTo.startPos.x + this.textAnnotation.belongsTo.endPos.x) / 2,
          (this.textAnnotation.belongsTo.startPos.y + this.textAnnotation.belongsTo.endPos.y) / 2
        ),
      ];
    } else {
      endPoints = [
        // top left
        this.textAnnotation.belongsTo.startPos,
        // top right
        new Point(
          this.textAnnotation.belongsTo.startPos.x + this.textAnnotation.belongsTo.bounds.width,
          this.textAnnotation.belongsTo.startPos.y
        ),
        // bottom right
        new Point(
          this.textAnnotation.belongsTo.startPos.x + this.textAnnotation.belongsTo.bounds.width,
          this.textAnnotation.belongsTo.startPos.y + this.textAnnotation.belongsTo.bounds.height
        ),
        // bottom left
        new Point(
          this.textAnnotation.belongsTo.startPos.x,
          this.textAnnotation.belongsTo.startPos.y + this.textAnnotation.belongsTo.bounds.height
        ),
      ];
    }

    let lastFromConnectPoint: Point | undefined;
    let lastToConnectPoint: Point | undefined;
    let lastDist = Number.MAX_VALUE;

    for (const i in startPoints) {
      if (Object.prototype.hasOwnProperty.call(startPoints, i)) {
        const startPoint = startPoints[i];
        for (const j in endPoints) {
          if (Object.prototype.hasOwnProperty.call(endPoints, j)) {
            const endPoint = endPoints[j];

            const dist: number = Math.sqrt(
              Math.pow(endPoint.x - startPoint.x, 2) + Math.pow(endPoint.y - startPoint.y, 2)
            );

            if (dist < lastDist) {
              lastDist = dist;
              lastFromConnectPoint = startPoint;
              lastToConnectPoint = endPoint;
            }
          }
        }
      }
    }

    if (lastFromConnectPoint && lastToConnectPoint) {
      return new Position(lastFromConnectPoint, lastToConnectPoint);
    }

    return undefined;
  }

  @Watch('modelElement.target')
  protected handleTargetChange(newVal: string): void {
    if (!newVal && this.modelElement) {
      this.modelElement.target = this.internalID;
      this.modelElement.translateTarget = this.internalID;
    }
  }

  get style(): { transform?: string } {
    if (this.modelElement) {
      return {
        transform:
          'translate(' +
          this.textAnnotation.textAnnotation.position.startPos.x +
          'px, ' +
          this.textAnnotation.textAnnotation.position.startPos.y +
          'px)',
      };
    }

    return {};
  }

  get innerstyle(): { transform?: string } {
    if (this.modelElement) {
      return {
        transform:
          'translate(' +
          (this.textAnnotation.textAnnotation.position.startPos.x + 5) +
          'px, ' +
          (this.textAnnotation.textAnnotation.position.startPos.y + 25) +
          'px)',
      };
    }

    return {};
  }

  get width(): number {
    if (this.modelElement) {
      return Math.abs(
        this.textAnnotation.textAnnotation.position.startPos.x - this.textAnnotation.textAnnotation.position.endPos.x
      );
    }

    return 100;
  }

  get height(): number {
    if (this.modelElement) {
      return Math.abs(
        this.textAnnotation.textAnnotation.position.startPos.y - this.textAnnotation.textAnnotation.position.endPos.y
      );
    }

    return 100;
  }

  get text(): string {
    if (this.modelElement) {
      // https://stackoverflow.com/a/784547
      return (
        '<div><span>' +
        htmlEntities(this.textAnnotation.textAnnotation.description).replace(/(?:\r\n|\r|\n)/g, '<br />') +
        '</span></div>'
      );
    }

    return '';
  }
}
