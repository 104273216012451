import Point from '@/models/Point';
import { JsonProperty, Serializable } from 'typescript-json-serializer';

@Serializable()
export default class Position {
  @JsonProperty('startPos')
  private _startPos: Point;
  @JsonProperty('endPos')
  private _endPos: Point = new Point(0, 0);

  constructor(startPos: Point, endPos?: Point) {
    this._startPos = startPos;
    if (endPos) {
      this._endPos = endPos;
    }
  }

  get startPos(): Point {
    return this._startPos;
  }

  set startPos(value: Point) {
    this._startPos = value;
  }

  get endPos(): Point {
    return this._endPos;
  }

  set endPos(value: Point) {
    this._endPos = value;
  }
}
