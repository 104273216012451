import AttributeType from '@/models/AttributeType';
import { JsonProperty, Serializable } from 'typescript-json-serializer';
import { slugify } from '@/serializer/helpers';

@Serializable()
export default abstract class ModelElementType {
  @JsonProperty('typeName')
  private readonly _typeName: string;
  @JsonProperty({
    name: 'attributeTypes',
    type: AttributeType,
  })
  private readonly _attributeTypes: AttributeType[];
  @JsonProperty('display')
  private readonly _display: string; // svg template

  /**
   * Used in auto grouping.
   * E.g. with systembounds in PPR -> Element is child if dropped onto element
   *
   * Parent defaults to true, because it's more likely to have multiple elements
   * that can have parents.
   *
   * Children defaults to false, because it's more likely to configure single elements
   * that can have children then otherwise.
   */
  @JsonProperty('canHaveParent')
  private _canHaveParent = true;
  @JsonProperty('canHaveChildren')
  private _canHaveChildren = false;

  @JsonProperty('layer')
  private _layer: string | null = 'General';

  constructor(typeName: string, attributeTypes: AttributeType[], display: string) {
    this._typeName = typeName;
    this._attributeTypes = attributeTypes;
    this._display = display;
  }

  get typeNameSlug(): string {
    return slugify(this.typeName);
  }

  get typeName(): string {
    return this._typeName;
  }

  get attributeTypes(): AttributeType[] {
    return this._attributeTypes;
  }

  get display(): string {
    return this._display;
  }

  get canHaveParent(): boolean {
    return this._canHaveParent;
  }

  set canHaveParent(value: boolean) {
    this._canHaveParent = value;
  }

  get canHaveChildren(): boolean {
    return this._canHaveChildren;
  }

  set canHaveChildren(value: boolean) {
    this._canHaveChildren = value;
  }

  get layer(): string | null {
    return this._layer;
  }

  set layer(value: string | null) {
    this._layer = value;
  }
}
