import { JsonProperty, Serializable } from 'typescript-json-serializer';

@Serializable()
export default class GroupUpdateInput {
  @JsonProperty('username')
  private _username: string;
  @JsonProperty('groups')
  private _groups: string[];

  constructor(username: string, groups: string[]) {
    this._username = username;
    this._groups = groups;
  }

  get username(): string {
    return this._username;
  }

  set username(value: string) {
    this._username = value;
  }

  get groups(): string[] {
    return this._groups;
  }

  set groups(value: string[]) {
    this._groups = value;
  }
}
