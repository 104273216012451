import ModelElement from '@/models/ModelElement';
import Point from '@/models/Point';
import Node from '@/models/Node';
import { JsonProperty, Serializable } from 'typescript-json-serializer';

@Serializable()
export default class Edge extends ModelElement {
  private _connectsFrom: Node;
  private _connectsTo: Node;

  @JsonProperty('connectsFromId')
  private _connectsFromId?: string = undefined;
  @JsonProperty('connectsToId')
  private _connectsToId?: string = undefined;

  constructor(
    type: string,
    name: string,
    description: string,
    startPos: Point,
    connectsFrom: Node,
    connectsTo: Node,
    created?: Date
  ) {
    super(type, name, description, startPos, created);
    this._connectsFrom = connectsFrom;
    if (this.connectsFrom) {
      this._connectsFromId = connectsFrom.id;
    }
    this._connectsTo = connectsTo;
    if (this.connectsTo) {
      this._connectsToId = connectsTo.id;
    }
  }

  get connectsFrom(): Node {
    return this._connectsFrom;
  }

  set connectsFrom(value: Node) {
    this._connectsFrom = value;
  }

  get connectsTo(): Node {
    return this._connectsTo;
  }

  set connectsTo(value: Node) {
    this._connectsTo = value;
  }

  get connectsFromId(): string | undefined {
    return this._connectsFromId;
  }

  set connectsFromId(value: string | undefined) {
    this._connectsFromId = value;
  }

  get connectsToId(): string | undefined {
    return this._connectsToId;
  }

  set connectsToId(value: string | undefined) {
    this._connectsToId = value;
  }
}
