import Point from '@/models/Point';
import { JsonProperty, Serializable } from 'typescript-json-serializer';
import Node from '@/models/Node';

@Serializable()
export default abstract class PlacementRule {
  @JsonProperty('ruleType')
  private readonly _type: string; // type of ModelElement

  protected constructor(type: string) {
    this._type = type;
  }

  get type(): string {
    return this._type;
  }

  public abstract placementAllowed(element: Node, parent: Node, point: Point): boolean;
}
