import Model from '@/models/Model';
import Node from '@/models/Node';
import AreaDrawable from '@/models/drawables/AreaDrawable';
import Drawable from '@/models/drawables/Drawable';
import Bounds from '@/models/Bounds';
import InsidePlacementRule from '@/models/InsidePlacementRule';
import PlacementRule from '@/models/PlacementRule';
import ModelConfig from '@/models/ModelConfig';

// https://gist.github.com/codeguy/6684588#gistcomment-3231045
const slugify = (text: string): string => {
  if (!text) {
    return text;
  }
  const from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;';
  const to = 'aaaaaeeeeeiiiiooooouuuunc------';

  const newText = text.split('').map((letter, i) => letter.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i)));

  return newText
    .toString() // Cast to string
    .toLowerCase() // Convert the string to lowercase letters
    .trim() // Remove whitespace from both sides of a string
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/&/g, '-y-') // Replace & with 'and'
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-'); // Replace multiple - with single -
};

const findNodeWithId = (nodes: Node[], id: string): Node | undefined => {
  return nodes.find((node) => {
    return node.id && node.id === id;
  });
};

const filterTemporaryDrawables = (model: Model): Model => {
  model.drawables = model.drawables.filter((drawable) => {
    return drawable.type !== 'TextAnnotationDrawable' && drawable.type !== 'MarkerDrawable';
  });

  return model;
};

const fixNodeReferencesOfEdges = (model: Model): Model => {
  if (model.nodes && model.edges) {
    for (const i in model.edges) {
      if (Object.prototype.hasOwnProperty.call(model.edges, i)) {
        const edge = model.edges[i];

        const fromId = edge.connectsFromId;
        const toId = edge.connectsToId;

        if (!fromId || !toId) {
          continue;
        }

        const fromNode = findNodeWithId(model.nodes, fromId);
        const toNode = findNodeWithId(model.nodes, toId);

        if (fromNode) {
          edge.connectsFrom = fromNode;
        }

        if (toNode) {
          edge.connectsTo = toNode;
        }
      }
    }
  }
  return model;
};

const fixBoundsOfDrawable = (model: Model): Model => {
  if (model.drawables) {
    for (const i in model.drawables) {
      if (Object.prototype.hasOwnProperty.call(model.drawables, i)) {
        const drawable = model.drawables[i];

        if (drawable.endPos && drawable.endPos.x > 0 && drawable.endPos.y > 0) {
          drawable.bounds = new Bounds(
            drawable.endPos.x - drawable.startPos.x,
            drawable.endPos.y - drawable.startPos.y
          );
        }
      }
    }
  }
  return model;
};

const fixNodeReferencesOfNodeParentId = (model: Model): Model => {
  if (model.nodes) {
    for (const i in model.nodes) {
      if (Object.prototype.hasOwnProperty.call(model.nodes, i)) {
        const node = model.nodes[i];

        if (node.parentId) {
          // find node with id and set node on element
          node.parentNode = findNodeWithId(model.nodes, node.parentId);
        }
      }
    }
  }
  return model;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
const placementRuleType = (placementRule: any): any => {
  if (
    Object.prototype.hasOwnProperty.call(placementRule, 'inside') &&
    Object.prototype.hasOwnProperty.call(placementRule, 'strict')
  ) {
    return InsidePlacementRule;
  }

  return PlacementRule;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
const drawablePredicate = (drawable): any => {
  switch (drawable.type) {
    case 'AreaDrawable':
      return AreaDrawable;
    default:
      return Drawable;
  }
};

const htmlEntities = (str: string): string => {
  return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
};

const syncAttributesOfBidirectionalEdges = (model: Model, config: ModelConfig): Model => {
  model.edges.forEach((edge) => {
    const edgeType = config.getEdgeType(edge.type);
    if (edgeType) {
      if (edgeType.bidirectional) {
        // find other direction
        const otherDirectionEdge = model.edges.find(
          (otherEdge) =>
            otherEdge.type === edge.type &&
            otherEdge.connectsToId === edge.connectsFromId &&
            otherEdge.connectsFromId === edge.connectsToId
        );
        if (otherDirectionEdge) {
          otherDirectionEdge.attributes = edge.attributes;
        }
      }
    }
  });

  return model;
};

export {
  slugify,
  findNodeWithId,
  fixNodeReferencesOfEdges,
  fixNodeReferencesOfNodeParentId,
  drawablePredicate,
  fixBoundsOfDrawable,
  placementRuleType,
  filterTemporaryDrawables,
  htmlEntities,
  syncAttributesOfBidirectionalEdges,
};
