import _Vue from 'vue';
import Feature from '@/features/Feature';
import { Features } from '@/enums/Features';

/* This plugin serves as proof-of-concept for vue-router routes introduced by plugins */
export default function BlueprintPlugin(Vue: typeof _Vue, options: any): void {
  // bail out if feature is not active
  if (!Feature.isEnabled(Features.BLUEPRINT)) {
    return;
  }

  // add plugin routes
  const routes = [
    {
      path: '/plugin-blueprint',
      name: 'blueprint',
      props: true,
      component: () => import('./views/Blueprint.vue'),
      meta: {
        title: 'Blueprint',
      },
    },
  ];

  if (Vue.prototype.$additionalRoutes === undefined) {
    Vue.prototype.$additionalRoutes = [];
  }

  // add routes
  Vue.prototype.$additionalRoutes = Vue.prototype.$additionalRoutes.concat(routes);
}
