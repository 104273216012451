import MainService from '@/services/MainService';
import { deserialize, serialize } from 'typescript-json-serializer';
import UserInfo from '@/models/users/UserInfo';
import UserTagged from '@/models/users/UserTagged';
import { AxiosResponse } from 'axios';
import UserTagChanges from '@/models/users/UserTagChanges';
import LdapUser from '@/models/users/LdapUser';
import LdapUserPublic from '@/models/users/LdapUserPublic';
import GroupUpdateInput from '@/models/users/GroupUpdateInput';
import UserPWResetInput from '@/models/users/UserPWResetInput';

export default class UserService {
  private static mainURL = '/userTagInfo';
  private static userURL = '/userInfo';

  public static addNewUser(ldapUser: LdapUser): Promise<LdapUser> {
    return MainService.post(this.userURL, JSON.stringify(serialize(ldapUser))).then((response) => {
      return deserialize(response.data, LdapUser);
    });
  }

  public static getUserInfoById(username: string): Promise<UserInfo> {
    return MainService.get(this.userURL + '/' + username).then((response) => {
      return deserialize(response.data, UserInfo);
    });
  }

  public static getUserInfoByIdsArray(usernames: string[]): Promise<UserInfo[]> {
    return MainService.post(this.userURL + '/all', JSON.stringify(serialize(usernames))).then((response) => {
      return response.data.map((item) => deserialize(item, UserInfo));
    });
  }

  public static getById(uid?: string): Promise<UserTagged> {
    return MainService.get(this.mainURL, { params: { uid } }).then((response) => {
      return deserialize(response.data, UserTagged);
    });
  }

  public static update(userTaggedId: number, userTagChanges: UserTagChanges): Promise<UserTagged> {
    return MainService.patch(this.mainURL + '/' + userTaggedId + '/', JSON.stringify(serialize(userTagChanges))).then(
      (response) => {
        return deserialize(response.data, UserTagged);
      }
    );
  }

  public static updateUser(ldapUser: LdapUserPublic): Promise<UserInfo> {
    return MainService.put(this.userURL, JSON.stringify(serialize(ldapUser))).then((response) => {
      return deserialize(response.data, UserInfo);
    });
  }

  public static importUsersCSV(csv: File): Promise<unknown> {
    const formData = new FormData();
    formData.append('file', csv);
    return MainService.post(this.userURL + '/admin/import', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then((response) => {
      return response.data;
    });
  }

  public static updatePasswordByAdmin(username: string, newpassword: string): Promise<UserInfo> {
    return MainService.put(this.userURL + '/admin/password', null, {
      params: { uid: username, newPassword: newpassword },
    }).then((response) => {
      return deserialize(response.data, UserInfo);
    });
  }

  public static updatePassword(oldPassword: string, newPassword: string): Promise<UserInfo> {
    return MainService.put(this.userURL + '/password', null, {
      params: { oldPassword: oldPassword, newPassword: newPassword },
    }).then((response) => {
      return deserialize(response.data, UserInfo);
    });
  }

  public static sendResetPasswordEmail(username: string): Promise<any> {
    return MainService.post(this.userURL + '/password/reset', null, {
      params: { uid: username },
    }).then((response) => {
      return deserialize(response.data, UserInfo);
    });
  }

  public static updateUserGroups(username: string, groups: string[]): Promise<UserInfo> {
    const groupUpdate = new GroupUpdateInput(username, groups);
    return MainService.put(this.userURL + '/admin/groups', JSON.stringify(serialize(groupUpdate))).then((response) => {
      return deserialize(response.data, UserInfo);
    });
  }

  public static delete(userTaggedId: number): Promise<AxiosResponse> {
    return MainService.delete(this.mainURL + '/' + userTaggedId + '/');
  }

  public static deleteUser(username: string): Promise<AxiosResponse> {
    return MainService.delete(this.userURL + '/admin/user', {
      params: {
        username: username,
      },
    });
  }

  public static getAllUserInfos(): Promise<UserInfo[]> {
    return MainService.get('/userInfo').then((response) => {
      return response.data.map((item) => deserialize(item, UserInfo));
    });
  }

  public static login(username: string, password: string): Promise<AxiosResponse> {
    return MainService.post('/api/login', { username, password });
  }

  public static resetPassword(password: string, secret: string): Promise<UserInfo> {
    const pwResetInput = new UserPWResetInput(secret, password);
    return MainService.put(this.userURL + '/password/reset', JSON.stringify(serialize(pwResetInput))).then(
      (response) => {
        return deserialize(response.data, UserInfo);
      }
    );
  }
}
